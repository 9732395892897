import { useEffect, useState } from "react";
import {
  AdminBarberRepository,
  BarberRepository,
} from "../../Domain/Repository";
import { useParams } from "react-router-dom";
import { Reservation } from "../../Domain/Model";
import { LoaderView } from "../Components/LoaderView";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { showErrorToast } from "../../Utils/ToastUtil";

export function DeleteClientReservationView({
  repository,
}: {
  repository: BarberRepository;
}) {
  const { id, reservation } = useParams();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [reservationData, setReservationData] = useState<Reservation | null>(
    null
  );
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showExpiredDialog, setShowExpiredDialog] = useState<boolean>(false);

  useEffect(() => {
    loadReservation();
  }, []);

  const loadReservation = async () => {
    const clientReservation = await repository.getReservation(
      id ?? "",
      reservation ?? ""
    );

    console.log("LOADING");
    console.log(id);
    console.log(reservation);
    if (clientReservation !== null) {
      const currentTimestamp = Date.now();
      if (clientReservation.slotStartTime > currentTimestamp) {
        setReservationData(clientReservation);
      } else {
        setShowExpiredDialog(true);
      }
    } else {
      setShowError(true);
    }
    setShowLoader(false);
  };

  const deleteReservation = async () => {
    setShowLoader(true);
    console.log(reservation);
    const result = await repository.deleteReservationCF(
      id ?? "",
      reservation ?? ""
    );
    if (result) {
      setShowSuccess(true);
    } else {
      showErrorToast("Si è verificato un' errore. Ti preghiamo di riprovare.");
    }
    setShowLoader(false);
  };

  if (showLoader) {
    return <LoaderView />;
  }
  if (showError) {
    return (
      <main>
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Si è verificato un'errore
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Non è stato possibile recuperare i dettagli della tua prenotazione
          </p>
          <div className="mt-10 mx-10 flex items-center justify-center gap-x-6">
            <a
              href={`https://gobarber.it/app/barber/${id}`}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prenota un nuovo appuntamento
            </a>
            <a
              href="mailto:gobarber.info@gmail.com"
              className="text-sm font-semibold text-gray-900"
            >
              Contatta il supporto <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    );
  }
  if (showExpiredDialog) {
    return (
      <main>
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Si è verificato un'errore
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Non è possibile eliminare una prenotazione passata.
          </p>
          <div className="mt-10 mx-10 flex items-center justify-center gap-x-6">
            <a
              href={`https://gobarber.it/app/barber/${id}`}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prenota un nuovo appuntamento
            </a>
            <a
              href="mailto:gobarber.info@gmail.com"
              className="text-sm font-semibold text-gray-900"
            >
              Contatta il supporto <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    );
  }
  if (showSuccess) {
    return (
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Prenotazione annullata con successo
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            La tua prenotazione è stata annullata. Puoi prenotarne una nuova
            oppure chiudere questa pagina.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={`https://gobarber.it/app/barber/${id}`}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prenota un nuovo appuntamento
            </a>
          </div>
        </div>
      </main>
    );
  }
  if (reservationData !== null) {
    const day = new Date(reservationData?.slotStartTime ?? 0).getDate();
    const month = new Date(reservationData?.slotStartTime ?? 0).getMonth() + 1;
    return (
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            La tua prenotazione
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {reservationData?.service.name} con {reservationData?.assignedTo} il
            giorno {day}
            {"/"}
            {month} alle ore {reservationData?.formattedString}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              onClick={() => {
                deleteReservation();
              }}
              className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Annulla prenotazione
            </button>
          </div>
        </div>
      </main>
    );
  }
  return <LoaderView />;
}

import { useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { useAuthStatus } from "../hook/useAuthStatus";
import { BarberShop, Reservation } from "../../Domain/Model";
import { CustomerItem } from "../Components/CustomerItem";
import { DashboardTabViewProps } from "./DashboardView";

type Customer = {
  customerName: string;
  customerEmail: string;
  customerPhone: string;
};

export function DashboardCustomersView({
  repository,
  pageIndex,
  selectedIndex,
}: DashboardTabViewProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [customerList, setCustomerList] = useState<Customer[]>([]);

  const { userEmail } = useAuthStatus();

  useEffect(() => {
    if (userEmail !== null) {
      loadInfo();
    }
  }, [userEmail]);

  useEffect(() => {
    // Used to reload page information when this is the active tab
    if (selectedIndex === pageIndex && userEmail !== null) {
      loadInfo();
    }
  }, [selectedIndex]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      await repository.getBarberShopInfo(userEmail);
      const appointments = await repository.getLoadedAppointments();
      loadCustomerList(appointments);
    }
  };

  const loadCustomerList = (reservations: Reservation[]) => {
    const uniqueCustomersMap = new Map<string, Customer>();

    reservations.forEach((reservation) => {
      const { customerPhone, customerName, customerEmail } = reservation;

      if (!uniqueCustomersMap.has(customerPhone)) {
        uniqueCustomersMap.set(customerPhone, {
          customerName,
          customerEmail,
          customerPhone,
        });
      }
    });
    const sortedCustomersMap = new Map(
      [...uniqueCustomersMap.entries()].sort((a, b) => {
        const nameA = a[1].customerName.toLowerCase();
        const nameB = b[1].customerName.toLowerCase();
        return nameA.localeCompare(nameB);
      })
    );

    setCustomerList(Array.from(sortedCustomersMap.values()));
  };

  const handleSearch = (event: any) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  const filteredList =
    searchTerm !== ""
      ? Array.from(customerList.values()).filter(
          (customer) =>
            customer.customerName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            customer.customerEmail
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            customer.customerPhone
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      : Array.from(customerList.values());

  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">Clienti</p>
          </div>

          <label className="mb-2 text-sm font-medium text-gray-900 sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Ricerca cliente"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <ul role="list" className="mt-4 divide-y divide-gray-100">
            {filteredList.map((value) => (
              <CustomerItem
                key={value.customerEmail}
                fullName={value.customerName}
                phone={value.customerPhone}
                email={value.customerEmail}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

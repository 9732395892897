import { LandingHeroComponent } from "../Components/LandingHeroComponent";
import { LandingParallaxComponent } from "../Components/LandingParallaxComponent";
import { LandingClientFeaturesComponent } from "../Components/LandingClientFeaturesComponent";
import { LandingServiceFeaturesComponent } from "../Components/LandingServiceFeaturesComponent";
import { LandingPriceComponent } from "../Components/LandingPriceComponent";
import { LandingStickerComponent } from "../Components/LandingStickerComponent";
import { LandingReviewsComponent } from "../Components/LandingReviewsComponent";
import { LandingContactComponent } from "../Components/LandingContactComponent";
import { LandingFooterComponent } from "../Components/LandingFooterComponent";

export function LandingPageView() {
  return (
    <div>
      <LandingHeroComponent />
      <LandingParallaxComponent />
      <LandingServiceFeaturesComponent />
      <LandingClientFeaturesComponent />
      <LandingPriceComponent />
      <LandingReviewsComponent />
      <LandingStickerComponent />
      <LandingContactComponent />
      <LandingFooterComponent />
    </div>
  );
}

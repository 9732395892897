import { useState } from "react";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";

type BarberEmployeeItemProps = {
  barberName: string;
  services: string[];
  onEditClicked: () => void;
  onDelete: () => void;
};

export function BarberEmployeeItem({
  barberName,
  services,
  onEditClicked,
  onDelete,
}: BarberEmployeeItemProps) {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);

  return (
    <div className="mt-2 flex flex-1 flex-col">
      <div>
        <div className="flex justify-between text-base font-medium text-gray-900">
          <h3>
            <p>{barberName}</p>
          </h3>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Servizi: {services.join(", ")}
        </p>
      </div>
      <div className="mt-2 flex flex-1 items-end justify-between text-sm">
        <button
          onClick={() => {
            onEditClicked();
          }}
          type="button"
          className="font-medium text-neutral-950 hover:text-neutral-800"
        >
          Modifica
        </button>
        <div className="flex">
          <button
            onClick={() => {
              setShowDeleteConfirmationDialog(true);
            }}
            type="button"
            className="font-medium text-neutral-950 hover:text-neutral-800"
          >
            Elimina
          </button>
        </div>
      </div>
      <hr className="my-4 h-0.5 border-t-0 bg-blue-100 opacity-100 dark:opacity-50" />
      {showDeleteConfirmationDialog && (
        <DeleteConfirmationDialog
          title={"Conferma eliminazione"}
          text={`Stai per eliminare il membro dello staff ${barberName}`}
          onDismiss={() => setShowDeleteConfirmationDialog(false)}
          onDeleteConfirmed={() => {
            setShowDeleteConfirmationDialog(false);
            onDelete();
          }}
        />
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import { useAuthStatus } from "../hook/useAuthStatus";
import { AdminBarberRepository } from "../../Domain/Repository";
import { BarberShop } from "../../Domain/Model/BarberShop";
import { phoneIsValid } from "../../Utils/FieldUtil";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { showErrorToast, showSuccessToast } from "../../Utils/ToastUtil";
import { DashboardTabViewProps } from "./DashboardView";

export function DashboardPersonalDataView({
  repository,
}: DashboardTabViewProps) {
  const [barberInfo, setBarberInfo] = useState<BarberShop | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const { userEmail } = useAuthStatus();
  const { handleChangeFullName, handleChangePhoneNumber, handleResetPassword } =
    useAdminBarberShopModelController(repository);

  useEffect(() => {
    loadInfo();
  }, [userEmail]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      const info = await repository.getBarberShopInfo(userEmail);
      setBarberInfo(info);
      setFullName(info?.fullName ?? "");
      setPhoneNumber(info?.phone ?? "");
    }
  };
  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Dati personali
            </p>
          </div>
          <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-2">
            <div>
              <label
                htmlFor="full-name"
                className="block text-sm font-medium leading-6 text-black"
              >
                Nome e Cognome
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="given-name"
                  value={fullName}
                  onChange={(value) => {
                    setFullName(value.target.value);
                  }}
                  className="block w-full rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
                />
              </div>
              <button
                className={`mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  fullName.trim() !== (barberInfo?.fullName ?? "") &&
                  fullName.trim() !== "" &&
                  fullName.trim().length > 3
                    ? "block"
                    : "invisible"
                }`}
                onClick={async () => {
                  const result = await handleChangeFullName(fullName);
                  if (result) {
                    setBarberInfo((prevBarberShopInfo: BarberShop | null) => {
                      if (!prevBarberShopInfo) {
                        return null;
                      }
                      return {
                        ...prevBarberShopInfo,
                        fullName: fullName,
                      };
                    });
                    showSuccessToast("Informazioni aggiornate");
                  } else {
                    showErrorToast("Si è verificato un errore");
                  }
                }}
              >
                Salva
              </button>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-black"
              >
                Telefono
              </label>
              <div className="mt-2">
                <input
                  type="tel"
                  name="phoneNumber"
                  id="full-name"
                  autoComplete="tel"
                  value={phoneNumber}
                  onChange={(value) => {
                    setPhoneNumber(value.target.value.trim());
                  }}
                  className="block w-full rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
                />
              </div>
              <button
                className={`mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  phoneNumber.trim() !== (barberInfo?.phone ?? "") &&
                  phoneNumber.trim() !== "" &&
                  phoneIsValid(phoneNumber.trim())
                    ? "block"
                    : "invisible"
                }`}
                onClick={async () => {
                  const result = await handleChangePhoneNumber(phoneNumber);
                  if (result) {
                    setBarberInfo((prevBarberShopInfo: BarberShop | null) => {
                      if (!prevBarberShopInfo) {
                        return null;
                      }
                      return {
                        ...prevBarberShopInfo,
                        phone: phoneNumber,
                      };
                    });
                    showSuccessToast("Telefono aggiornato");
                  } else {
                    showErrorToast("Si è verificato un errore");
                  }
                }}
              >
                Salva
              </button>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-black"
              >
                Password
              </label>
              <div className="mt-2">
                <button
                  className={
                    "flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  }
                  onClick={async () => {
                    const result = await handleResetPassword(userEmail ?? "");
                    if (result) {
                      showSuccessToast(
                        "Ti abbiamo inviato una email per resettare la password"
                      );
                    } else {
                      showErrorToast("Si è verificato un errore");
                    }
                  }}
                >
                  Resetta Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

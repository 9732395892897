import leaf from "../../images/leaf.png";

type NoDataComponentProps = {
  text: string;
};
export function NoDataComponent({ text }: NoDataComponentProps) {
  return (
    <div className="flex mx-auto w-full justify-center items-center py-10 flex-col">
      <img src={leaf} alt="leaf" className="h-24 w-24" />
      <p className="mt-3 text-lg text-center px-3">{text} </p>
    </div>
  );
}

import { useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { LoaderView } from "../Components/LoaderView";
import payment_confirmed from "../../images/payment_confirmed.jpg";
import payment_confirmed_error from "../../images/payment_confirmed_error.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStatus } from "../hook/useAuthStatus";

type PaymentConfirmedViewProps = {
  repository: AdminBarberRepository;
};
export function PaymentConfirmedView({
  repository,
}: PaymentConfirmedViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userEmail } = useAuthStatus();
  useEffect(() => {
    loadInfo();
  }, [userEmail]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      const info = await repository.getBarberShopInfo(userEmail);
      const queryParameters = new URLSearchParams(window.location.search);
      const checkoutId = queryParameters.get("session_id");
      if (
        checkoutId !== null &&
        checkoutId !== undefined &&
        checkoutId !== ""
      ) {
        updateSubscriptionInfo(checkoutId);
      }
    }
  };

  const updateSubscriptionInfo = async (checkoutId: string) => {
    const result = await repository.getCheckoutSessionAndUpdateSubscriptionInfo(
      checkoutId
    );
    setSuccess(result);
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoaderView />;
  }
  return (
    <div className="block w-full min-h-full justify-center items-center py-8">
      <p className="text-black text-2xl font-bold font-semibold text-center">
        {success ? "Il servizio è ora attivo!" : "Qualcosa è andato storto"}
      </p>
      <img
        className="w-full sm:w-2/3 lg:w-2/4 xl:w-1/3 mx-auto mt-6 px-6"
        src={success ? payment_confirmed : payment_confirmed_error}
        alt="confirmation-image"
      />
      <p className="mt-6 text-center text-black px-8 md:px-48">
        {success
          ? "Grazie per aver deciso di utilizzare GoBarber. Non dimenticarti che siamo a tua completa disposizone per qualsiasi dubbio o necessità. Non vediamo l'ora di far crescere insieme il tuo negozio!"
          : "Se visualizzi questo errore probabilmente è perchè il tuo pagamento è andato a buon fine ma si è verificato un problema durante l'aggiornamento dei tuoi dati. Contatta il supporto alla seguente email: gobarber.info@gmail.com"}
      </p>
      <button
        className="mt-6 mx-auto block h-full justify-center items-center rounded-md bg-slate-700 px-6 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => {
          navigate("/app/dashboard");
        }}
      >
        Torna alla dashboard
      </button>
    </div>
  );
}

import { useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { BarberShop, Reservation } from "../../Domain/Model";
import { useAuthStatus } from "../hook/useAuthStatus";
import Calendar from "react-calendar";
import { ReservationItem } from "../Components/ReservationItem";
import morning from "../../images/morning.png";
import noon from "../../images/noon.png";
import afternoon from "../../images/afternoon.png";
import evening from "../../images/evening.png";
import leaf from "../../images/leaf.png";
import { NoReservationsComponent } from "../Components/NoReservationsComponent";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { NoDataComponent } from "../Components/NoDataComponent";
import { DashboardTabViewProps } from "./DashboardView";
import { PlusIcon } from "@heroicons/react/24/outline";

export function DashboardReservationsView({
  repository,
  pageIndex,
  selectedIndex,
}: DashboardTabViewProps) {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [filteredReservations, setFilteredReservations] = useState<
    Reservation[]
  >([]);
  const { userEmail } = useAuthStatus();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { handleDeleteReservation } =
    useAdminBarberShopModelController(repository);
  const [shopUrl, setShopUrl] = useState<string>("");

  useEffect(() => {
    if (userEmail !== null) {
      loadInfo();
    }
  }, [userEmail]);

  useEffect(() => {
    // Used to reload page information when this is the active tab
    if (selectedIndex === pageIndex && userEmail !== null) {
      loadInfo();
    }
  }, [selectedIndex]);

  const loadInfo = async () => {
    const shopUrl = await repository.getBarberShopLink();
    setShopUrl(shopUrl);
    await repository.getBarberShopInfo(userEmail!);
    repository.loadAppointments(onReservationsUpdated);
  };

  useEffect(() => {
    if (selectedDate !== null) {
      filterReservations(reservations);
    }
  }, [selectedDate]);

  useEffect(() => {
    filterReservations(reservations);
  }, [reservations]);

  const onReservationsUpdated = (newReservations: Reservation[]) => {
    const newArray = [...newReservations];
    newArray.sort((a, b) => a.slotStartTime - b.slotStartTime);
    setReservations(newArray);
  };

  const filterReservations = (newReservations: Reservation[]) => {
    var result = [...newReservations];
    result = result.filter((reservation) => {
      const reservationDate = new Date(reservation.slotStartTime);
      return (
        reservationDate.getDate() === selectedDate.getDate() &&
        reservationDate.getMonth() === selectedDate.getMonth() &&
        reservationDate.getFullYear() === selectedDate.getFullYear()
      );
    });
    setFilteredReservations(result);
  };
  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block lg:flex py-6 px-4 md:px-8">
      <div className="mx-auto w-full h-min md:w-2/3 rounded-xl shadow-lg bg-white p-8">
        <p className="mb-4 text-black text-2xl font-bold w-full">Data</p>
        <Calendar
          onChange={(value: any, event: any) => setSelectedDate(value)}
          value={selectedDate}
        />
      </div>
      <div className="mx-auto w-full h-min md:w-2/3 rounded-xl shadow-lg bg-white mt-10 lg:mt-0 lg:ml-10 p-8">
        <div className="flex flex-1 mb-8 items-center justify-between text-sm">
          <p className="mb-4 text-black text-2xl font-bold w-full">
            Prenotazioni
          </p>
          <button
            onClick={() => {
              window.open(shopUrl, "_blank");
            }}
            type="button"
            className="block w-min justify-center rounded-full bg-slate-700 px-2.5 py-2.5 text-sm font-semibold leading-2 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="h-4 text-white" />
          </button>
        </div>
        {filteredReservations.length === 0 ? (
          <NoDataComponent text={"Non ci sono prenotazioni in questo giorno"} />
        ) : (
          <>
            <div className="w-full mt-10">
              <div className="flex day-time-container">
                <img src={morning} width={30} alt="morning" />
                <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                  Mattina
                </p>
              </div>
              {filteredReservations.filter((e) => e.dayTime === "morning")
                .length === 0 ? (
                <NoReservationsComponent />
              ) : (
                <ul role="list" className="mt-4 divide-y divide-gray-100">
                  {filteredReservations
                    .filter((e) => e.dayTime === "morning")
                    .map((reservation: Reservation) => (
                      <ReservationItem
                        key={reservation.id}
                        reservation={reservation}
                        onDelete={() => {
                          handleDeleteReservation(reservation.id);
                        }}
                        repository={repository}
                      />
                    ))}
                </ul>
              )}
            </div>
            <div className="w-full mt-10">
              <div className="flex day-time-container">
                <img src={noon} width={30} alt="noon" />
                <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                  Pranzo
                </p>
              </div>
              {filteredReservations.filter((e) => e.dayTime === "lunch")
                .length === 0 ? (
                <NoReservationsComponent />
              ) : (
                <ul role="list" className="mt-4 divide-y divide-gray-100">
                  {filteredReservations
                    .filter((e) => e.dayTime === "lunch")
                    .map((reservation: Reservation) => (
                      <ReservationItem
                        key={reservation.id}
                        reservation={reservation}
                        onDelete={() => {
                          handleDeleteReservation(reservation.id);
                        }}
                        repository={repository}
                      />
                    ))}
                </ul>
              )}
            </div>
            <div className="w-full mt-10 ">
              <div className="flex day-time-container">
                <img src={afternoon} width={30} alt="afternoon" />
                <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                  Pomeriggio
                </p>
              </div>
              {filteredReservations.filter((e) => e.dayTime === "afternoon")
                .length === 0 ? (
                <NoReservationsComponent />
              ) : (
                <ul role="list" className="mt-4 divide-y divide-gray-100">
                  {filteredReservations
                    .filter((e) => e.dayTime === "afternoon")
                    .map((reservation: Reservation) => (
                      <ReservationItem
                        key={reservation.id}
                        reservation={reservation}
                        onDelete={() => {
                          handleDeleteReservation(reservation.id);
                        }}
                        repository={repository}
                      />
                    ))}
                </ul>
              )}
            </div>
            <div className="w-full my-10 ">
              <div className="flex day-time-container">
                <img src={evening} width={30} alt="evening" />
                <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                  Sera
                </p>
              </div>
              {filteredReservations.filter((e) => e.dayTime === "evening")
                .length === 0 ? (
                <NoReservationsComponent />
              ) : (
                <ul role="list" className="mt-4 divide-y divide-gray-100">
                  {filteredReservations
                    .filter((e) => e.dayTime === "evening")
                    .map((reservation: Reservation) => (
                      <ReservationItem
                        key={reservation.id}
                        reservation={reservation}
                        onDelete={() => {
                          handleDeleteReservation(reservation.id);
                        }}
                        repository={repository}
                      />
                    ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { Navigate, Outlet } from "react-router-dom";
import { useAuthStatus } from "../hook/useAuthStatus";
import { LoaderView } from "../Components/LoaderView";

export function PrivateRoute() {
  const { loggedIn, checkingStatus } = useAuthStatus();
  if (checkingStatus) {
    return <LoaderView />;
  }
  if (!loggedIn) {
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
}

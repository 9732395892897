import React from "react";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";

const GradientColors = () => {
  return (
    <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
      <stop offset="30%" stopColor="#1F3A8A" stopOpacity={0.4} />
      <stop offset="75%" stopColor="#356bff" stopOpacity={0.3} />
      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.2} />
    </linearGradient>
  );
};
interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string | number | Date;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white rounded-md p-2 shadow-md">
        <p className="text-black">{`Prenotazioni ${label}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return <></>;
};

type AnalyticsChartComponentProps = {
  data: any[];
};
export function AnalyticsChartComponent({
  data,
}: AnalyticsChartComponentProps) {
  return (
    <div className="analytics-chart">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart margin={{ left: 0, right: 0 }} data={data}>
          <defs>
            <GradientColors />
          </defs>
          <Tooltip content={<CustomTooltip />} />

          <CartesianGrid strokeDasharray="4 4" stroke="#1F3A8A" opacity={0.4} />
          <XAxis
            dataKey="month"
            tick={{ fill: "#B6BAC3" }}
            tickMargin={10}
            stroke="#EEEEEE"
          />
          <YAxis width={30} tick={{ fill: "#B6BAC3" }} stroke="#EEEEEE" />
          <Area
            dataKey="reservations"
            type="monotone"
            stroke="#1F3A8A"
            strokeWidth={3}
            strokeOpacity={1}
            fill="url(#colorView)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

import { UserCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

type ReservationItemProps = {
  fullName: string;
  phone: string;
  email: string;
};

export function CustomerItem({ fullName, phone, email }: ReservationItemProps) {
  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber.startsWith("+39")) {
      return `39${phoneNumber}`;
    }

    return phoneNumber;
  };
  return (
    <>
      {
        <li
          key={phone}
          className="flex justify-between items-end gap-x-6 py-5 "
        >
          <div className="flex min-w-1 gap-x-4">
            <UserCircleIcon className="h-12 w-12 text-sky-950" />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {fullName}
              </p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                {phone}
              </p>
              <a
                href={`https://api.whatsapp.com/send?phone=${formatPhoneNumber(
                  phone
                )}`}
                type="button"
                className="text-sm font-semibold text-neutral-950 hover:text-neutral-800"
              >
                Messaggia
              </a>
            </div>
          </div>
          <div className="shrink-1 overflow-hidden text-ellipsis whitespace-nowrap text-end sm:flex sm:flex-col items-end">
            <p className="mt-1 text-xs leading-5 text-gray-500">{email}</p>
            <a
              href={`mailto:${email}`}
              type="button"
              className="text-sm font-semibold text-neutral-950 hover:text-neutral-800"
            >
              Invia Email
            </a>
          </div>
        </li>
      }
    </>
  );
}

import { useEffect, useState } from "react";
import { BarberRepository } from "../../Domain/Repository";
import { useBarberShopModelController } from "../hook/useBarberShopModelController";
import { BarberShop, Service, Slot } from "../../Domain/Model";
import { LoaderView } from "../Components/LoaderView";
import { BarberShopClientHeaderView } from "./BarberShopClientHeaderView";
import { BarberShopClientCalendarView } from "./BarberShopClientCalendarView";
import { BarberShopClientButtonsView } from "./BarberShopClientButtonsView";
import { ConfirmationModalView } from "./ConfirmationModalView";
import { CommunicationAlertView } from "./CommunicationAlertView";
import { useLocation } from "react-router-dom";

type BarberShopClientViewProps = {
  repository: BarberRepository;
};

export function BarberShopClientView({
  repository,
}: BarberShopClientViewProps) {
  const [barberInfo, setBarberInfo] = useState<BarberShop | null>(null);
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedBarber, setSelectedBarber] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [enableReserveButton, setEnableReserveButton] =
    useState<boolean>(false);
  const [fieldReset, setFieldReset] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [reservationConfirmed, setReservationConfirmed] =
    useState<boolean>(false);
  const [showCommunicationAlert, setShowCommunicationAlert] =
    useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    getBarberInfoAndServices();
  }, []);

  useEffect(() => {
    setEnableReserveButton(
      fullName.length > 3 &&
        phoneIsValid(phoneNumber) &&
        mailIsValid(email) &&
        selectedService !== null &&
        selectedBarber !== null &&
        selectedSlot !== null
    );
  }, [
    fullName,
    phoneNumber,
    email,
    selectedService,
    selectedBarber,
    selectedSlot,
  ]);

  useEffect(() => {
    if (fieldReset) {
      setFieldReset(false);
    }
  }, [fieldReset]);

  function phoneIsValid(phone: string) {
    var phoneFormat =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (phone.match(phoneFormat)) {
      return true;
    } else {
      return false;
    }
  }
  function mailIsValid(email: string) {
    var mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const resetFields = () => {
    setFullName("");
    setPhoneNumber("");
    setEmail("");
    setSelectedService(null);
    setSelectedBarber(null);
    setSelectedSlot(null);
    setFieldReset(true);
  };
  const getBarberInfoAndServices = async () => {
    setLoading(true);
    const pageUrl = location.pathname;
    const barberUrl = pageUrl.split("/").pop() ?? "";
    const url = await repository.setBarberShop(barberUrl);
    const barberInfo = await repository.getBarberData();
    const mappedServices = await repository.getServices();
    setServices(mappedServices);
    setBarberInfo(barberInfo);
    setLoading(false);
    setShowCommunicationAlert(
      barberInfo?.communication !== undefined &&
        barberInfo?.communication !== ""
    );
  };

  if (barberInfo !== null) {
    const today = new Date();
    const plan = barberInfo.plan;
    const planExpirationDate = barberInfo.planExpirationDate;
    const planActive = barberInfo.planActive;
    if (
      (today.getTime() > planExpirationDate && plan === "free") ||
      (planActive === false &&
        today.getTime() > planExpirationDate &&
        plan !== "gift")
    ) {
      return (
        <div className="flex flex-col justify-center items-center min-h-screen">
          <p className="text-center text-2xl px-3">
            Ci scusiamo ma si è verificato un problema nella visualizzazione
            della pagina. Ricarica la pagina e se il problema persiste contatta
            il tuo barbiere.
          </p>
          <button
            className="mt-6 flex w-2/3 md:w-1/3 justify-center rounded-full bg-slate-700 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={async () => {
              window.location.reload();
            }}
          >
            Ricarica
          </button>
        </div>
      );
    }
  }

  return (
    <>
      <div className="flex flex-col gap-8 min-h-screen">
        <BarberShopClientHeaderView
          repository={repository}
          barberInfo={barberInfo}
          services={services}
          setFullNameCallback={(value: string) => setFullName(value)}
          setPhoneNumberCallback={(value: string) => setPhoneNumber(value)}
          setEmailCallback={(value: string) => setEmail(value)}
          setSelectedServiceCallback={(service: Service) =>
            setSelectedService(service)
          }
          setSelectedBarberCallback={(barber: string | null) =>
            setSelectedBarber(barber)
          }
          fieldReset={fieldReset}
        />
        <BarberShopClientCalendarView
          repository={repository}
          slotDuration={30}
          selectedBarber={selectedBarber}
          setSelectedSlotCallback={(slot: Slot | null) => setSelectedSlot(slot)}
        />
      </div>
      <div className="sticky bottom-0 bg-white">
        <BarberShopClientButtonsView
          repository={repository}
          userName={fullName}
          userPhone={phoneNumber}
          userEmail={email}
          selectedSlot={selectedSlot}
          isReserveButtonEnabled={enableReserveButton}
          onClearFields={(showConfirmationModal: boolean, success: boolean) => {
            if (showConfirmationModal) {
              setReservationConfirmed(success);
            }
            setShowConfirmationModal(showConfirmationModal);

            //resetFields();
          }}
          setLoading={(loading) => {
            setLoading(loading);
          }}
        />
      </div>
      {showConfirmationModal && (
        <ConfirmationModalView
          success={reservationConfirmed}
          onDismiss={() => {
            window.location.reload();
          }}
        />
      )}
      {showCommunicationAlert && (
        <CommunicationAlertView
          text={barberInfo?.communication ?? ""}
          onDismiss={() => {}}
        />
      )}
      {loading && <LoaderView />}
    </>
  );
}

import { useState } from "react";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";

type PromoItemComponentProps = {
  reservationId: string;
  name: string;
  service: string;
  days: string[];
  formattedTime: string;
  discountValue: string;
  onEditClicked: () => void;
  onDelete: () => void;
};
export function PromoItemComponent({
  reservationId,
  name,
  service,
  days,
  formattedTime,
  discountValue,
  onEditClicked,
  onDelete,
}: PromoItemComponentProps) {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  return (
    <div id={reservationId} className="mt-2 flex flex-1 flex-col">
      <div>
        <div className="flex justify-between text-base font-medium text-gray-900">
          <h3>
            <p>{name}</p>
          </h3>
          <p className="ml-4">{discountValue}%</p>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          {days.join(", ")} ({formattedTime})
        </p>
      </div>
      <div className="mt-2 flex flex-1 items-end justify-between text-sm">
        <button
          type="button"
          onClick={() => {
            onEditClicked();
          }}
          className="font-medium text-neutral-950 hover:text-neutral-800"
        >
          Modifica
        </button>
        <div className="flex">
          <button
            type="button"
            className="font-medium text-neutral-950 hover:text-neutral-800"
            onClick={() => setShowDeleteConfirmationDialog(true)}
          >
            Elimina
          </button>
        </div>
      </div>
      <hr className="my-4 h-0.5 border-t-0 bg-blue-100 opacity-100 dark:opacity-50" />
      {showDeleteConfirmationDialog && (
        <DeleteConfirmationDialog
          title={"Conferma eliminazione"}
          text={`Stai per eliminare la promozione ${name}`}
          onDismiss={() => setShowDeleteConfirmationDialog(false)}
          onDeleteConfirmed={() => {
            setShowDeleteConfirmationDialog(false);
            onDelete();
          }}
        />
      )}
    </div>
  );
}

import { CheckIcon } from "@heroicons/react/20/solid";
import review_1 from "../../images/review_1.png";
import review_2 from "../../images/review_2.png";
import review_3 from "../../images/review_3.png";

const includedFeatures = [
  "Dashboard personale",
  "Assistenza prima configurazione del negozio",
  "Supporto WhatsApp 24/7",
  "Email di conferma prenotazione illimitate",
];

export function LandingReviewsComponent() {
  return (
    <div id="reviews" className="bg-white pt-24 sm:pt-48">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-700">
            Cosa dicono di noi
          </h2>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Questo è quello che pensa chi ha provato il nostro servizio
          </h2>
        </div>
        <div className="mx-auto flex overflow-x-auto mt-16">
          <img className="w-10/12 sm:w-1/3" src={review_1} alt="review" />
          <img className="w-10/12 sm:w-1/3" src={review_2} alt="review" />
          <img className="w-10/12 sm:w-1/3" src={review_3} alt="review" />
        </div>
      </div>
    </div>
  );
}

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";

type ConfirmationModalViewProps = {
  success: boolean;
  onDismiss: () => void;
};

export function ConfirmationModalView({
  success,
  onDismiss,
}: ConfirmationModalViewProps) {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(open) => {
          setOpen(open);
          onDismiss();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex flex-col items-center">
                    <div
                      className={`mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full  ${
                        success ? "bg-green-100" : "bg-red-100"
                      }`}
                    >
                      {success ? (
                        <CheckIcon
                          className="h-8 w-8 text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationTriangleIcon
                          className="h-8 w-8 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>

                    <div className="mt-5 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {success
                          ? "Prenotazione Confermata"
                          : "Prenotazione Fallita"}
                      </Dialog.Title>
                      <div className="mt-3">
                        <p className="text-sm text-gray-500">
                          {success
                            ? "A breve riceverai una email con il riepilogo della tua prenotazione."
                            : "Si è verificato un errore. Ti preghiamo di riprovare."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex justify-center">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md  ${
                      success
                        ? "bg-blue-600 hover:bg-blue-500"
                        : "bg-red-600 hover:bg-red-500"
                    } px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3`}
                    onClick={() => {
                      setOpen(false);
                      onDismiss();
                    }}
                  >
                    Chiudi
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

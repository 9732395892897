import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Dashboard personale",
  "Assistenza prima configurazione del negozio",
  "Supporto WhatsApp 24/7",
  "Email di conferma prenotazione illimitate",
];

export function LandingPriceComponent() {
  return (
    <div id="price" className="bg-white pt-24 sm:pt-48">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-700">
            Alla portata di tutti
          </h2>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            GoBarber ti offre tutte queste funzionalità al prezzo di 2 tagli di
            capelli
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Investimento minimo, risultati enormi. Semplifica la gestione dei
            tuoi appuntamenti e aumenta la produttività nei tuoi servizi di
            oltre il 30%.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Zero rischi
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Ti offriamo una prova gratuita di 14 giorni in cui riceverai il
              nostro supporto per configurare il tuo negozio e promuovere il
              link di prenotazione ai tuoi clienti. E se al termine della tua
              prova gratuita non ti avessimo convinto, non avrai speso nemmeno
              1€!
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                Cosa è incluso
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Abbonamento mensile
                </p>

                <p className="mt-6 flex items-end justify-center gap-x-2">
                  <span className="strike-center text-xl text-center max-w-fit">
                    €49,99{" "}
                  </span>
                  <span className="text-3xl sm:text-5xl font-bold tracking-tight text-gray-900">
                    €34,99
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 ">
                    /mese
                  </span>
                </p>
                <a
                  href="/signup"
                  className="mt-10 block w-full rounded-md bg-blue-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Provalo gratis
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Pagamenti sicuri tramite Stripe e Paypal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  Service,
  BarberEmployee,
  Reservation,
  BarberShop,
  Promotion,
  SubscriptionData,
} from "../../Domain/Model";
import { auth, db } from "../../firebase.config";
import { AdminBarberDataSource } from "./AdminBarberDataSource";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  Unsubscribe,
  onSnapshot,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import axios from "axios";
import { RegistrationEmailTemplate } from "../../Domain/Model/RegistrationEmailTemplate";

export class OnMemoryAdminBarberDataSourceImpl
  implements AdminBarberDataSource
{
  barberShopInfo: BarberShop | null = null;
  barberShopUrl: string | null = null;
  private reservationsListener: Unsubscribe | null = null;
  private reservations: Reservation[] | null = null;
  private cachedReservations: Reservation[] | null = null;
  promotions: Promotion[] | null = null;

  async createAccount(
    name: string,
    phone: string,
    email: string,
    password: string
  ): Promise<boolean> {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const today = new Date();
      const expirationDate = new Date();
      expirationDate.setDate(today.getDate() + 14);

      const planExpirationDate = expirationDate.getTime();
      const barberShopsRef = collection(db, "barberShops");
      const registrationDate = new Date().toLocaleString("it-IT", {
        timeZone: "Europe/Rome",
      });
      await setDoc(doc(barberShopsRef), {
        fullName: name,
        phone: phone,
        email: email,
        registrationDate: registrationDate,
        businessName: "",
        address: "",
        breakStartTime: "",
        breakEndTime: "",
        plan: "free",
        planActive: false,
        planExpirationDate: planExpirationDate,
        stripeCustomerId: "",
        subscriptionId: "",
        invoices: [],
      });

      await this.sendConfirmationEmail({
        service_id: process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        template_id: process.env.REACT_APP_EMAIL_JS_REGISTRATION_TEMPLATE_ID,
        user_id: process.env.REACT_APP_EMAIL_JS_USER_ID,
        template_params: {
          user_name: name,
          to_email: email,
        },
      });
      return true;
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      return false;
    }
  }

  async login(email: string, password: string): Promise<boolean> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async resetPassword(email: string): Promise<boolean> {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async signOut(): Promise<boolean> {
    try {
      await signOut(auth);
      this.barberShopInfo = null;
      this.barberShopUrl = null;
      this.reservationsListener = null;
      this.reservations = [];
      this.promotions = null;
      return true;
    } catch (error) {
      return false;
    }
  }
  async setBarberShopName(name: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(docRef, { businessName: name }, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async setBarberShopAddress(address: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(docRef, { address: address }, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async addService(service: Service): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      var currentServices: Record<string, Service> | undefined =
        this.barberShopInfo?.services;
      if (currentServices === undefined) {
        currentServices = {};
      }
      currentServices[service.name] = {
        name: service.name,
        price: service.price,
        slotDuration: service.slotDuration,
      };
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(
        docRef,
        {
          services: {
            [service!.name]: {
              price: service.price,
              slotDuration: service.slotDuration,
            },
          },
        },
        { merge: true }
      );
      this.barberShopInfo!.services = currentServices;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async deleteService(serviceName: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      var currentServices: Record<string, Service> | undefined =
        this.barberShopInfo?.services;
      if (currentServices === undefined) {
        currentServices = {};
      }
      delete currentServices[serviceName];
      const employees = this.barberShopInfo?.employees || {};
      Object.values(employees).forEach((employee: any) => {
        if (employee.services && employee.services.includes(serviceName)) {
          employee.services = employee.services.filter(
            (service: string) => service !== serviceName
          );
        }
      });
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await updateDoc(docRef, {
        services: currentServices,
        employees: employees,
      });
      this.barberShopInfo!.services = currentServices;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async addBarberEmployee(employee: BarberEmployee): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      var currentEmployees: Record<string, Record<string, any>> | undefined =
        this.barberShopInfo?.employees;
      if (currentEmployees === undefined) {
        currentEmployees = {};
      }
      currentEmployees[employee.name] = {
        services: employee.services,
        shifts: employee.shifts,
      };
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await updateDoc(docRef, {
        employees: currentEmployees,
      });
      this.barberShopInfo!.employees = currentEmployees;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async deleteBarberEmployee(employeeName: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      var currentEmployees: Record<string, Record<string, any>> | undefined =
        this.barberShopInfo?.employees;
      if (currentEmployees === undefined) {
        currentEmployees = {};
      }

      delete currentEmployees[employeeName];
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await updateDoc(docRef, {
        employees: currentEmployees,
      });
      this.barberShopInfo!.employees = currentEmployees;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async setBreakTime(startTime: string, endTime: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(
        docRef,
        { breakStartTime: startTime, breakEndTime: endTime },
        { merge: true }
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async setCommunication(message: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await updateDoc(docRef, { communication: message });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async loadAppointments(
    onReservationsUpdated: (reservations: Reservation[]) => void
  ): Promise<void> {
    if (this.reservationsListener) {
      // Remove the current listener
      this.reservationsListener();
      this.reservationsListener = null;
    }
    if (this.barberShopUrl === null) {
      return;
    }
    const reservationsRef = collection(
      db,
      `barberShops/${this.barberShopUrl}/reservations`
    );

    this.reservations = [];

    this.reservationsListener = onSnapshot(
      reservationsRef,
      (snapshot: { docChanges: () => any[] }) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const reservation = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Reservation;
            this.reservations!.push(reservation);
          }
          if (change.type === "modified") {
            const modifiedReservation = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Reservation;
            const index = this.reservations!.findIndex(
              (r) => r.id === modifiedReservation.id
            );
            if (index !== -1) {
              this.reservations![index] = modifiedReservation;
            }
          }
          if (change.type === "removed") {
            const removedReservation = {
              ...change.doc.data(),
              id: change.doc.id,
            } as Reservation;
            this.reservations = this.reservations!.filter(
              (r) => r.id !== removedReservation.id
            );
          }
        });
        onReservationsUpdated(this.reservations!);
      }
    );
  }

  async getLoadedAppointments(): Promise<Reservation[]> {
    if (
      this.reservations !== null &&
      (this.cachedReservations === null ||
        this.reservations.length > this.cachedReservations.length)
    ) {
      this.cachedReservations = [];
      this.cachedReservations = [...this.reservations];
      return this.cachedReservations;
    } else if (this.cachedReservations !== null) {
      return this.cachedReservations;
    } else {
      try {
        const reservationsRef = collection(
          db,
          `barberShops/${this.barberShopUrl}/reservations`
        );
        const snapshot = await getDocs(reservationsRef);
        const reservations: Reservation[] = [];

        if (!snapshot.empty) {
          snapshot.docs.forEach((doc) => {
            const reservationData = doc.data();
            const reservation = {
              ...reservationData,
              id: doc.id,
            } as Reservation;
            reservations.push(reservation);
          });
        }
        this.cachedReservations = [...reservations];
        return reservations;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  }
  async deleteReservation(reservationId: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }

      const documentRef = doc(
        db,
        "barberShops",
        this.barberShopUrl,
        "reservations",
        reservationId
      );

      deleteDoc(documentRef)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return false;
        });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async getBarberShopInfo(email: string): Promise<BarberShop | null> {
    if (this.barberShopInfo !== null) {
      return this.barberShopInfo;
    }
    const collectionRef = collection(db, "barberShops");
    const querySnapshot = await getDocs(
      query(collectionRef, where("email", "==", email))
    );
    if (!querySnapshot.empty) {
      const docSnap: any = querySnapshot.docs[0];
      let barberShopData: BarberShop = {
        fullName: docSnap.data().fullName,
        phone: docSnap.data().phone,
        email: docSnap.data().email,
        plan: docSnap.data().plan,
        planExpirationDate: docSnap.data().planExpirationDate,
        planActive: docSnap.data().planActive,
        stripeCustomerId: docSnap.data().stripeCustomerId,
        subscriptionId: docSnap.data().subscriptionId,
        invoices: docSnap.data().invoices,
        businessName: docSnap.data().businessName,
        address: docSnap.data().address,
        openingTimes: docSnap.data().openingTimes,
        breakStartTime: docSnap.data().breakStartTime,
        breakEndTime: docSnap.data().breakEndTime,
        employees: docSnap.data().employees,
        services: docSnap.data().services,
        communication: docSnap.data().communication,
      };
      this.barberShopUrl = docSnap.id;
      this.barberShopInfo = barberShopData;
      await this.getSubscriptionExpirationDate();
      return this.barberShopInfo;
    } else {
      this.barberShopInfo = null;
      return null;
    }
  }
  async getBarberShopLink(): Promise<string> {
    var barberShopLink = this.barberShopUrl ?? "";
    if (barberShopLink === "") {
      return "";
    } else {
      barberShopLink = `https://www.gobarber.it/app/barber/${this.barberShopUrl}`;
      return barberShopLink;
    }
  }
  async getPromotions(): Promise<Promotion[]> {
    try {
      if (this.barberShopUrl === null) {
        this.promotions = [];
        return [];
      }

      const promotionsRef = collection(
        db,
        `barberShops/${this.barberShopUrl}/promotions`
      );

      const snapshot = await getDocs(promotionsRef);
      const promotions: Promotion[] = [];

      if (!snapshot.empty) {
        snapshot.docs.forEach((doc) => {
          const promotionData = doc.data();
          const promotion: Promotion = {
            id: doc.id,
            name: promotionData.name,
            service: promotionData.service,
            days: promotionData.days,
            startTime: promotionData.startTime,
            endTime: promotionData.endTime,
            discountValue: promotionData.discountValue,
          };
          promotions.push(promotion);
        });
      }
      this.promotions = promotions;

      return promotions;
    } catch (error) {
      this.promotions = [];
      return [];
    }
  }

  async setPromotion(promotion: Promotion): Promise<string | null> {
    try {
      if (this.barberShopUrl === null) {
        this.promotions = [];
        return null;
      }
      const promotionsRef = collection(
        db,
        "barberShops",
        this.barberShopUrl,
        "promotions"
      );
      console.log(promotion);

      if (promotion.id !== null) {
        const promotionDoc = doc(promotionsRef, promotion.id);
        await updateDoc(promotionDoc, {
          name: promotion.name,
          service: promotion.service,
          days: promotion.days,
          startTime: promotion.startTime,
          endTime: promotion.endTime,
          discountValue: promotion.discountValue,
        });
        return promotion.id;
      } else {
        const docRef = await addDoc(promotionsRef, {
          name: promotion.name,
          service: promotion.service,
          days: promotion.days,
          startTime: promotion.startTime,
          endTime: promotion.endTime,
          discountValue: promotion.discountValue,
        });
        return docRef.id;
      }
    } catch (error) {
      return null;
    }
  }

  async deletePromotion(promotionId: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }

      const documentRef = doc(
        db,
        "barberShops",
        this.barberShopUrl,
        "promotions",
        promotionId
      );

      deleteDoc(documentRef)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return false;
        });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async setFullName(fullName: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(docRef, { fullName: fullName }, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async setPhoneNumber(phoneNumber: string): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(docRef, { phone: phoneNumber }, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateSubscriptionInfo(): Promise<boolean> {
    return true;
  }

  async getCheckoutSessionAndUpdateSubscriptionInfo(
    checkoutId: string
  ): Promise<boolean> {
    try {
      if (this.barberShopUrl === null) {
        return false;
      }
      const response = await axios.get(
        `https://api.stripe.com/v1/checkout/sessions/${checkoutId}`,
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
          },
        }
      );

      const customer = response.data["customer"];
      const paymentStatus = response.data["payment_status"];
      const invoiceId = response.data["invoice"];
      const subscriptionId = response.data["subscription"];
      const plan = "premium";
      const planActive = true;
      const today = new Date();
      const expirationDate = new Date();
      expirationDate.setDate(today.getDate() + 31);

      const planExpirationDate = expirationDate.getTime();

      const subscriptionData: SubscriptionData = {
        customerId: customer,
        paymentStatus: paymentStatus,
        subscriptionId: subscriptionId,
        plan: plan,
        planActive: planActive,
        planExpirationDate: planExpirationDate,
      };
      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(docRef, subscriptionData, { merge: true });
      if (this.barberShopInfo !== null) {
        this.barberShopInfo.stripeCustomerId = customer;
        this.barberShopInfo.subscriptionId = subscriptionId;
        this.barberShopInfo.plan = "premium";
        this.barberShopInfo.planActive = true;
        this.barberShopInfo.planExpirationDate = planExpirationDate;
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async cancelSubscription(): Promise<boolean> {
    try {
      const subscriptionId = this.barberShopInfo?.subscriptionId ?? "";
      if (this.barberShopUrl === null || subscriptionId === "") {
        return false;
      }
      await axios.delete(
        `https://api.stripe.com/v1/subscriptions/${subscriptionId}`,
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
          },
        }
      );

      const plan = "premium";
      const planActive = false;

      const barberShopsRef = collection(db, "barberShops");
      const docRef = doc(barberShopsRef, this.barberShopUrl);
      await setDoc(
        docRef,
        { plan: plan, planActive: planActive },
        { merge: true }
      );
      if (this.barberShopInfo !== null) {
        this.barberShopInfo.plan = "premium";
        this.barberShopInfo.planActive = false;
      }
      await this.getSubscriptionExpirationDate();

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getSubscriptionExpirationDate(): Promise<number | null> {
    try {
      const subscriptionId = this.barberShopInfo?.subscriptionId ?? "";
      if (subscriptionId !== "" && this.barberShopUrl !== null) {
        const response = await axios.get(
          `https://api.stripe.com/v1/subscriptions/${subscriptionId}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.REACT_APP_STRIPE_SK_KEY,
            },
          }
        );
        const currentPeriodEnd = response.data["current_period_end"] * 1000;
        const barberShopsRef = collection(db, "barberShops");
        const docRef = doc(barberShopsRef, this.barberShopUrl);
        await setDoc(
          docRef,
          { planExpirationDate: currentPeriodEnd },
          { merge: true }
        );
        if (this.barberShopInfo !== null) {
          this.barberShopInfo!.planExpirationDate = currentPeriodEnd;
        }
        return currentPeriodEnd;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async sendConfirmationEmail(data: RegistrationEmailTemplate): Promise<void> {
    await axios.post(`https://api.emailjs.com/api/v1.0/email/send`, data);
  }
}

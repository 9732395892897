import {
  ArrowTrendingUpIcon,
  BuildingStorefrontIcon,
  MegaphoneIcon,
  QueueListIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Gestisci il tuo negozio",
    description:
      "Configura gli orari del tuo negozio, aggiungi i servizi che offri e il personale dello staff che lavora con te. In questo modo i tuoi clienti potranno prenotare il servizio con il loro dipendente preferito",
    icon: BuildingStorefrontIcon,
  },
  {
    name: "Visualizza le prenotazioni",
    description:
      "Dimenticati del telefono, visualizza tutte le prenotazioni nel tuo pannello: passate, presenti e future",
    icon: QueueListIcon,
  },
  {
    name: "Crea promozioni mirate",
    description:
      "Incentiva i clienti a venire da te nelle fasce orarie meno frequentate. Scegli i giorni e le fasce orarie in cui vuoi promuovere i tuoi servizi e comunicalo ai tuoi clienti",
    icon: MegaphoneIcon,
  },
  {
    name: "Analizza i risultati",
    description:
      "Controlla i risultati del tuo negozio. Quali sono i servizi più richiesti, i giorni e le fasce orarie più affollate, i tuoi clienti più fedeli.",
    icon: ArrowTrendingUpIcon,
  },
];

export function LandingServiceFeaturesComponent() {
  return (
    <div
      id="features"
      className="bg-white mt-[700px] sm:mt-[750px] pt-8 sm:pt-12"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-700">
            Successo su misura
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Prenotazioni veloci. Analisi accurate. Sconti vincenti.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Migliora la qualità dei tuoi servizi e ottimizza i tempi al massimo.
            <br></br>Mostra ai tuoi clienti che sono la tua priorità.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-900">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="flex w-full justify-center items-center mt-20">
          <a
            href="/signup"
            className="rounded-md bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Inizia la tua prova gratuita
          </a>
        </div>
      </div>
    </div>
  );
}

export function CreditsView() {
  return (
    <div className="w-full py-6 px-6 flex flex-col">
      <p className="text-black text-2xl font-bold">Credits</p>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/sunrise"
        title="sunrise icons"
      >
        Sunrise icons created by Mehwish - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/sunset"
        title="sunset icons"
      >
        Sunset icons created by Mehwish - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/crescent"
        title="crescent icons"
      >
        Crescent icons created by Arkinasi - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/sun"
        title="sun icons"
      >
        Sun icons created by Freepik - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/no-data"
        title="no data icons"
      >
        No data icons created by Ghozi Muhtarom - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/no-media"
        title="no media icons"
      >
        No media icons created by popcornarts - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/calendar"
        title="calendar icons"
      >
        Calendar icons created by Freepik - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/time-and-date"
        title="time and date icons"
      >
        Time and date icons created by Tama Design - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/email"
        title="email icons"
      >
        Email icons created by Tomas Knop - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/facebook"
        title="facebook icons"
      >
        Facebook icons created by Freepik - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://www.flaticon.com/free-icons/whatsapp"
        title="whatsapp icons"
      >
        Whatsapp icons created by Freepik - Flaticon
      </a>
      <a
        className="mt-2"
        href="https://it.freepik.com/autore/stories"
        title="freepik stories"
      >
        Images of storyset by Freepik
      </a>
    </div>
  );
}

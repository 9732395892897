import { useEffect, useState } from "react";
import { useAuthStatus } from "../hook/useAuthStatus";
import { AdminBarberRepository } from "../../Domain/Repository";
import { BarberShop } from "../../Domain/Model/BarberShop";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { DeleteConfirmationDialog } from "../Components/DeleteConfirmationDialog";
import { showErrorToast, showSuccessToast } from "../../Utils/ToastUtil";
import { DashboardTabViewProps } from "./DashboardView";

export function DashboardSubscriptionManagementView({
  repository,
}: DashboardTabViewProps) {
  const [barberInfo, setBarberInfo] = useState<BarberShop | null>(null);
  const [showCancelSubConfirmationDialog, setShowCancelSubConfirmationDialog] =
    useState<boolean>(false);
  const { userEmail } = useAuthStatus();
  const { handleCancelSubscription } =
    useAdminBarberShopModelController(repository);
  useAdminBarberShopModelController(repository);

  const UNDEFINED = -999;
  const FREE_TRIAL_PERIOD = 0;
  const EXPIRED_FREE_TRIAL = -1;
  const PREMIUM_ACTIVE = 1;
  const CANCELED_SUBSCRIPTION = -2;

  useEffect(() => {
    loadInfo();
  }, [userEmail]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      const info = await repository.getBarberShopInfo(userEmail);
      setBarberInfo(info);
    }
  };

  const getPlanName = (): string => {
    if (barberInfo?.plan === undefined || barberInfo?.plan === null) {
      return "-";
    } else if (barberInfo?.plan === "free") {
      return "Prova gratuita";
    } else {
      return "Premium";
    }
  };
  const getPlanType = (): number => {
    const currentTimestamp = Date.now();
    const planActive = barberInfo?.planActive;
    if (barberInfo?.plan === undefined || barberInfo?.plan === null) {
      return UNDEFINED;
    } else if (barberInfo?.plan === "gift") {
      return PREMIUM_ACTIVE;
    } else if (barberInfo?.plan === "free") {
      if (currentTimestamp > barberInfo?.planExpirationDate) {
        return EXPIRED_FREE_TRIAL;
      } else {
        return FREE_TRIAL_PERIOD;
      }
    } else {
      if (planActive === true) {
        return PREMIUM_ACTIVE;
      } else if (planActive === false) {
        return CANCELED_SUBSCRIPTION;
      } else {
        return UNDEFINED;
      }
    }
  };

  const getExpirationDate = (): string => {
    if (
      !barberInfo?.planExpirationDate ||
      barberInfo?.planExpirationDate === -1
    ) {
      return "-";
    } else {
      const timestamp = barberInfo?.planExpirationDate;
      const date = new Date(timestamp);

      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const formattedDate = date.toLocaleDateString(undefined, options);
      return formattedDate;
    }
  };
  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Il tuo abbonamento
            </p>
          </div>
          <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-3 gap-x-8 gap-y-6">
            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                Piano
              </p>
              <p className="text-black text-center">{getPlanName()}</p>
            </div>
            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                {getPlanType() <= 0 ? "Scadenza" : "Prossimo rinnovo"}
              </p>
              <p className="text-black text-center">{getExpirationDate()}</p>
            </div>

            <div>
              <button
                className={`mx-auto block h-full justify-center items-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  getPlanType() <= 0
                    ? "bg-slate-700 hover:bg-slate-600"
                    : "bg-red-700 hover:bg-red-600"
                }`}
                onClick={async () => {
                  if (getPlanType() === UNDEFINED) {
                    return;
                  }
                  if (getPlanType() <= 0) {
                    window.open(
                      process.env.REACT_APP_SUBSCRIPTION_PAYMENT_URL,
                      "_self"
                    );
                  } else {
                    setShowCancelSubConfirmationDialog(true);
                  }
                }}
              >
                {getPlanType() <= 0
                  ? "Esegui l'upgrade"
                  : "Disdici abbonamento"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCancelSubConfirmationDialog && (
        <DeleteConfirmationDialog
          title={"Conferma disdetta"}
          text={
            "Proseguendo il tuo abbonamento verrà interrotto e perderai tutti i benefici di GoBarber"
          }
          onDismiss={() => setShowCancelSubConfirmationDialog(false)}
          onDeleteConfirmed={async () => {
            setShowCancelSubConfirmationDialog(false);
            const result = await handleCancelSubscription();
            if (result) {
              const newBarberShopInfo = await repository.getBarberShopInfo(
                userEmail!
              );
              setBarberInfo((prevBarberShopInfo: BarberShop | null) => {
                if (!prevBarberShopInfo || !newBarberShopInfo) {
                  return null;
                }
                return {
                  ...prevBarberShopInfo,
                  plan: newBarberShopInfo?.plan,
                  planActive: newBarberShopInfo?.planActive,
                  planExpirationDate: newBarberShopInfo?.planExpirationDate,
                };
              });
              showSuccessToast(
                "Sottoscrizione al servizio cancellata con successo"
              );
            } else {
              showErrorToast("Si è verificato un errore");
            }
          }}
        />
      )}
    </div>
  );
}

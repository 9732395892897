import signupImage from "../../images/signup.jpg";
import logo from "../../images/logo_black.svg";
import { AdminBarberRepository } from "../../Domain/Repository";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { useEffect, useState } from "react";
import { phoneIsValid, mailIsValid } from "../../Utils/FieldUtil";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../Utils/ToastUtil";
import { CircularLoaderComponent } from "../Components/CircularLoaderComponent";
import { useAuthStatus } from "../hook/useAuthStatus";
type SignUpViewProps = {
  repository: AdminBarberRepository;
};
export function SignUpView({ repository }: SignUpViewProps) {
  const { handleRegistration } = useAdminBarberShopModelController(repository);
  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { loggedIn } = useAuthStatus();

  useEffect(() => {
    if (loggedIn) {
      navigate("/app/dashboard");
    }
  }, [loggedIn]);

  useEffect(() => {
    const isFormValid =
      fullName.length > 3 &&
      phoneIsValid(phoneNumber) &&
      mailIsValid(email) &&
      password.length > 6;
    setIsButtonEnabled(isFormValid);
  }, [fullName, phoneNumber, email, password]);
  return (
    <>
      <div className="mx-auto w-full h-full grid max-w-full grid-cols-1 items-center gap-y-16 px-6 lg:max-w-full lg:grid-cols-2 lg:px-0 bg-white">
        <div className="h-full mx-auto items-center hidden lg:flex bg-white">
          <img src={signupImage} alt="signup" className="mx-auto p-6" />
        </div>

        <div className="sm:mx-auto h-full sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-28 w-auto mt-10"
            src={logo}
            alt="GoBarber Logo"
            onClick={() => navigate("/")}
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-black">
            Registra un account
          </h2>

          <div>
            <label
              htmlFor="full-name"
              className="mt-5 block text-sm font-medium leading-6 text-black"
            >
              Nome e Cognome
            </label>
            <div className="mt-2">
              <input
                id="full-name"
                name="full-name"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoComplete="given-name"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="phone"
              className="mt-5 block text-sm font-medium leading-6 text-black"
            >
              Telefono
            </label>
            <div className="mt-2">
              <input
                id="phone"
                name="phone"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                autoComplete="tel"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="mt-5 block text-sm font-medium leading-6 text-black"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="mt-5 block text-sm font-medium leading-6 text-black"
            >
              Password
            </label>
            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <p className="py-2 text-sm text-gray-500">
            Registrandoti accetti i{" "}
            <a
              href="/terms-and-conditions"
              className="font-semibold leading-6 text-blue-600 hover:text-slate-500"
            >
              termini di servizio
            </a>{" "}
            e le{" "}
            <a
              href="/privacy-policy"
              className="font-semibold leading-6 text-blue-600 hover:text-slate-500"
            >
              privacy policy
            </a>
          </p>
          <button
            onClick={async () => {
              if (isButtonEnabled) {
                setIsLoading(true);
                setIsButtonEnabled(false);
                const result = await handleRegistration(
                  fullName,
                  phoneNumber,
                  email,
                  password
                );
                if (result) {
                  setIsLoading(false);
                  setIsButtonEnabled(true);
                  navigate("/app/dashboard");
                } else {
                  setIsLoading(false);
                  setIsButtonEnabled(true);
                  showErrorToast(
                    "Si è verificato un errore durante la registrazione"
                  );
                }
              } else {
                showErrorToast("Informazioni mancanti o errate");
              }
            }}
            type="submit"
            className={`mt-4 flex w-full justify-center rounded-md bg-blue-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              isButtonEnabled
                ? "cursor-pointer"
                : "opacity-40 cursor-not-allowed"
            }`}
          >
            {isLoading && <CircularLoaderComponent />}
            Registrati
          </button>

          <p className="py-10 text-center text-sm text-gray-500">
            Hai già un account?{" "}
            <a
              href="/signin"
              className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
            >
              Accedi
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

import { Fragment, useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { BarberEmployee, BarberShop, Service } from "../../Domain/Model";
import { useAuthStatus } from "../hook/useAuthStatus";
import { LoaderView } from "../Components/LoaderView";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import {
  ArrowUturnLeftIcon,
  BackwardIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { ServiceItemComponent } from "../Components/ServiceItemComponent";
import { AddServiceComponent } from "../Components/AddServiceComponent";
import { BarberEmployeeItem } from "../Components/BarberEmployeeItem";
import { AddBarberEmployeeComponent } from "../Components/AddBarberEmployeeComponent";
import { NoDataComponent } from "../Components/NoDataComponent";
import { CommunicationAlertView } from "../Client/CommunicationAlertView";
import { showErrorToast, showSuccessToast } from "../../Utils/ToastUtil";
import { DashboardTabViewProps } from "./DashboardView";

export function DashboardShopView({ repository }: DashboardTabViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [barberShopInfo, setBarberShopInfo] = useState<BarberShop | null>(null);
  const [businessName, setBusinessName] = useState<string>("");
  const [businessAddress, setBusinessAddress] = useState<string>("");
  const [selectedBreakStartTime, setSelectedBreakStartTime] =
    useState<string>("");
  const [selectedBreakEndTime, setSelectedBreakEndTime] = useState<string>("");
  const [selectedBreakStartTimeIndex, setSelectedBreakStartTimeIndex] =
    useState<number>(0);
  const [communication, setCommunication] = useState<string>("");
  const [addServiceMode, setAddServiceMode] = useState<boolean>(false);
  const [addBarberEmployeeMode, setAddBarberEmployeeMode] =
    useState<boolean>(false);
  const [serviceName, setServiceName] = useState<string>("");
  const [serviceDuration, setServiceDuration] = useState<number>(30);
  const [servicePrice, setServicePrice] = useState<string>("");
  const [barberToEdit, setBarberToEdit] = useState<string>("");
  const [barberShiftsToEdit, setBarberShiftsToEdit] = useState<
    Record<string, any>
  >({});
  const [barberServiceToEdit, setBarberServiceToEdit] = useState<string[]>([]);
  const [subExpirationMessage, setSubExpirationMessage] = useState<string>("");

  const { userEmail } = useAuthStatus();
  const {
    handleChangeBusinessName,
    handleChangeBusinessAddress,
    handleChangeBreakTime,
    handleDeleteService,
    handleDeleteBarberEmployee,
    handleChangeCommunication,
  } = useAdminBarberShopModelController(repository);
  const breakStartTimes: string[] = [
    "Seleziona un orario",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
  ];
  const breakEndTimes: string[] = [
    "Seleziona un orario",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
  ];

  const serviceDurationOptions: Record<number, string> = {
    30: "30 minuti",
    45: "45 minuti",
    60: "1 ora",
    90: "1 ora e 30 minuti",
    120: "2 ore",
    150: "2 ore e 30 minuti",
    180: "3 ore",
    210: "3 ore e 30 minuti",
    240: "4 ore",
  };
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    loadInfo();
  }, [userEmail]);

  const loadInfo = async () => {
    if (barberShopInfo === null && userEmail !== null) {
      const info: BarberShop | null = await repository.getBarberShopInfo(
        userEmail
      );
      setBarberShopInfo(info);
      setBusinessName(info?.businessName ?? "");
      setBusinessAddress(info?.address ?? "");
      setSelectedBreakStartTime(info?.breakStartTime ?? "");
      setSelectedBreakEndTime(info?.breakEndTime ?? "");
      setCommunication(info?.communication ?? "");
      if (info?.breakStartTime) {
        const startIndex = breakStartTimes.findIndex(
          (time) => time === info?.breakStartTime
        );
        setSelectedBreakStartTimeIndex(startIndex);
      }
      checkSubscription(info);
      setIsLoading(false);
    }
  };

  const checkSubscription = (info: BarberShop | null) => {
    const today = new Date();
    const subExpirationDate = info?.planExpirationDate;
    const timeDifference =
      (subExpirationDate ?? today.getTime()) - today.getTime();

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const plan: string = info?.plan ?? "";
    const planActive: boolean = info?.planActive ?? false;

    if (plan === "gift") {
      return;
    }
    if (plan === "free") {
      if (daysRemaining <= 0) {
        const subMessage = `La tua prova gratuita è terminata!. Vai in "Gestione abbonamento" per rinnovare il tuo piano affinchè i tuoi clienti possano trovarti online.`;
        setSubExpirationMessage(subMessage);
      } else if (daysRemaining <= 5) {
        const subMessage = `Ti ricordiamo che la tua prova gratuita scade tra ${daysRemaining} giorni. Vai in "Gestione abbonamento" per rinnovare il piano prima della scadenza.`;
        setSubExpirationMessage(subMessage);
      }
    } else if (plan === "premium") {
      if (!planActive) {
        if (daysRemaining <= 0) {
          const subMessage = `Il tuo abbonamento è terminato. Vai in "Gestione abbonamento" per rinnovarlo affinchè i tuoi clienti possano trovarti online.`;
          setSubExpirationMessage(subMessage);
        } else {
          const subMessage = `Ti ricordiamo che il tuo piano sarà attivo ancora per ${daysRemaining} giorni. Vai in "Gestione abbonamento" per modificare la tua iscrizione`;
          setSubExpirationMessage(subMessage);
        }
      }
    }
  };

  if (isLoading) {
    return <LoaderView />;
  }

  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="block rounded-xl shadow-lg bg-white p-8 mb-12">
        <p className="mb-4 text-black text-2xl font-bold w-full ">
          Informazioni sul tuo negozio
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 items-end">
          {/* Shop name */}
          <div className="sm:col-span-1 sm:col-start-1">
            {barberShopInfo?.businessName === "" && (
              <div
                className="w-full bg-orange-100 border-l-4 border-orange-500 rounded text-orange-700 p-2"
                role="alert"
              >
                <p className="font-bold">Informazione mancante!</p>
                <p>Inserisci il nome del tuo negozio</p>
              </div>
            )}
            <div>
              <label
                htmlFor="barberShopName"
                className="mt-5 block text-sm font-medium leading-6 text-black"
              >
                Nome del negozio
              </label>
              <div className="mt-2">
                <input
                  id="barberShopName"
                  name="barberShopName"
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                  className="block w-full rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
                />
              </div>
              <button
                className={`mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  businessName !== (barberShopInfo?.businessName ?? "") &&
                  businessName !== ""
                    ? "block"
                    : "invisible"
                }`}
                onClick={async () => {
                  const result = await handleChangeBusinessName(businessName);
                  if (result) {
                    setBarberShopInfo(
                      (prevBarberShopInfo: BarberShop | null) => {
                        if (!prevBarberShopInfo) {
                          return null;
                        }
                        return {
                          ...prevBarberShopInfo,
                          businessName: businessName,
                        };
                      }
                    );
                    showSuccessToast("Nome aggiornato");
                  } else {
                    showErrorToast("Si è verificato un errore");
                  }
                }}
              >
                Salva
              </button>
            </div>
          </div>
          {/* Shop address*/}
          <div className="sm:col-span-1">
            {barberShopInfo?.address === "" && (
              <div
                className="w-full bg-orange-100 border-l-4 rounded border-orange-500 text-orange-700 p-2"
                role="alert"
              >
                <p className="font-bold">Informazione mancante!</p>
                <p>Inserisci l'indirizzo del tuo negozio</p>
              </div>
            )}
            <div>
              <label
                htmlFor="email"
                className="mt-5 block text-sm font-medium leading-6 text-black"
              >
                Indirizzo
              </label>
              <div className="mt-2">
                <input
                  id="barberShopName"
                  name="barberShopName"
                  type="text"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  required
                  className="block w-full rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
                />
              </div>
              <button
                className={`mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  businessAddress !== (barberShopInfo?.address ?? "") &&
                  businessAddress !== ""
                    ? "block"
                    : "invisible"
                }`}
                onClick={async () => {
                  const result = await handleChangeBusinessAddress(
                    businessAddress
                  );
                  if (result) {
                    setBarberShopInfo(
                      (prevBarberShopInfo: BarberShop | null) => {
                        if (!prevBarberShopInfo) {
                          return null;
                        }
                        return {
                          ...prevBarberShopInfo,
                          address: businessAddress,
                        };
                      }
                    );
                    showSuccessToast("Indirizzo aggiornato");
                  } else {
                    showErrorToast("Si è verificato un errore");
                  }
                }}
              >
                Salva
              </button>
            </div>
          </div>
          {/* Start break time */}
          <div className="sm:col-span-1">
            <Listbox
              value={selectedBreakStartTime}
              onChange={(value) => {
                setSelectedBreakStartTime(value);
                const startIndex = breakStartTimes.findIndex(
                  (time) => time === value
                );
                setSelectedBreakStartTimeIndex(startIndex);
                setSelectedBreakEndTime("");
              }}
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-black">
                    Inizio pausa pranzo
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">
                          {selectedBreakStartTime
                            ? `${selectedBreakStartTime}`
                            : "Seleziona un orario"}
                        </span>
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        {open ? (
                          <ChevronUpIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                        {breakStartTimes.map((time) => (
                          <Listbox.Option
                            key={time}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "bg-indigo-600 text-white"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-3 pr-9"
                              )
                            }
                            value={time}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center justify-between">
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {time}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                    <div>
                      <button className="invisible mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Salva
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          {/* End break time */}
          <div className="sm:col-span-1">
            <Listbox
              value={selectedBreakEndTime}
              onChange={(value) => {
                setSelectedBreakEndTime(value);
              }}
              disabled={
                selectedBreakStartTime === "" ||
                selectedBreakStartTimeIndex === 0
              }
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-black">
                    Fine pausa pranzo
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button
                      className={` ${
                        selectedBreakStartTime === "" ||
                        selectedBreakStartTimeIndex === 0
                          ? "bg-transparent"
                          : "bg-white"
                      } relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6`}
                    >
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">
                          {selectedBreakEndTime
                            ? `${selectedBreakEndTime}`
                            : "Seleziona un orario"}
                        </span>
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        {open ? (
                          <ChevronUpIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                        {breakEndTimes
                          .slice(selectedBreakStartTimeIndex)
                          .map((time) => (
                            <Listbox.Option
                              key={time}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={time}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center justify-between">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {time}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                  <div>
                    <button
                      className={`mt-4 flex w-full justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                        (selectedBreakStartTime !==
                          (barberShopInfo?.breakStartTime ?? "") &&
                          selectedBreakEndTime !== "" &&
                          selectedBreakEndTime !== breakEndTimes[0]) ||
                        (selectedBreakStartTimeIndex === 0 &&
                          barberShopInfo?.breakStartTime !== "")
                          ? "block"
                          : "invisible"
                      }`}
                      onClick={async () => {
                        var startTime = selectedBreakStartTime;
                        var endTime = selectedBreakEndTime;
                        if (selectedBreakStartTimeIndex === 0) {
                          startTime = "";
                          endTime = "";
                        }

                        const result = await handleChangeBreakTime(
                          startTime,
                          endTime
                        );
                        if (result) {
                          setBarberShopInfo(
                            (prevBarberShopInfo: BarberShop | null) => {
                              if (!prevBarberShopInfo) {
                                return null;
                              }
                              return {
                                ...prevBarberShopInfo,
                                breakStartTime: startTime,
                                breakEndTime: endTime,
                              };
                            }
                          );
                          showSuccessToast("Orari aggiornati");
                        } else {
                          showErrorToast("Si è verificato un errore");
                        }
                      }}
                    >
                      Salva
                    </button>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
        <div className="sm:col-span-4">
          <label
            htmlFor="barberShopName"
            className="block text-sm font-medium leading-6 text-black"
          >
            Comunicazioni per gli utenti
          </label>
          <div className="mt-2">
            <textarea
              id="communications"
              name="communications"
              rows={3}
              className="block w-full ring-1 ring-inset ring-gray-300 rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
              value={communication}
              onChange={(e) => {
                setCommunication(e.target.value);
              }}
            />
            <button
              className={`mt-4 flex justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                communication !== (barberShopInfo?.communication ?? "")
                  ? "block"
                  : "invisible"
              }`}
              onClick={async () => {
                const result = await handleChangeCommunication(communication);
                if (result) {
                  setBarberShopInfo((prevBarberShopInfo: BarberShop | null) => {
                    if (!prevBarberShopInfo) {
                      return null;
                    }
                    return {
                      ...prevBarberShopInfo,
                      communication: communication,
                    };
                  });
                  showSuccessToast("Comunicazione aggiornata");
                } else {
                  showErrorToast("Si è verificato un errore");
                }
              }}
            >
              Salva
            </button>
          </div>
        </div>
      </div>
      {/* Services */}
      <div className="grid grid-cols-1 gap-x-8 gap-y-12 mb-12 md:grid-cols-2 mt-5">
        <div className="rounded-xl shadow-lg bg-white p-8 sm:col-span-1">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              I tuoi servizi
            </p>
            <button
              onClick={() => {
                if (addServiceMode) {
                  setServiceName("");
                  setServiceDuration(30);
                  setServicePrice("");
                  setAddServiceMode(false);
                } else {
                  setAddServiceMode(true);
                }
              }}
              type="button"
              className="block w-min justify-center rounded-full bg-slate-700 px-2.5 py-2.5 text-sm font-semibold leading-2 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {addServiceMode ? (
                <ArrowUturnLeftIcon className="h-4 text-white" />
              ) : (
                <PlusIcon className="h-4 text-white" />
              )}
            </button>
          </div>
          {addServiceMode ? (
            <AddServiceComponent
              previousServiceName={serviceName}
              previousServiceDuration={serviceDuration}
              previousServicePrice={servicePrice}
              setAddServiceModeCallback={(service: Service | null) => {
                setAddServiceMode(false);
                setServiceName("");
                setServiceDuration(30);
                setServicePrice("");
                if (service !== null) {
                  const updatedServices = {
                    ...barberShopInfo?.services,
                    [service.name]: {
                      name: service.name,
                      price: service.price,
                      slotDuration: service.slotDuration,
                    },
                  };
                  setBarberShopInfo((prevBarberShopInfo: BarberShop | null) => {
                    if (!prevBarberShopInfo) {
                      return null;
                    }
                    return {
                      ...prevBarberShopInfo,
                      services: updatedServices,
                    };
                  });
                  showSuccessToast("Servizio salvato");
                } else {
                  showErrorToast("Si è verificato un errore");
                }
              }}
              repository={repository}
            />
          ) : (barberShopInfo?.services ?? []).length === 0 ? (
            <NoDataComponent text={"Non hai aggiunto ancora nessun servizio"} />
          ) : (
            Object.entries(barberShopInfo?.services ?? [])
              .sort()
              .map(([key, value]) => (
                <ServiceItemComponent
                  key={key}
                  name={key}
                  price={value.price}
                  slotDuration={serviceDurationOptions[value.slotDuration]}
                  onEditClicked={() => {
                    setServiceName(key);
                    setServiceDuration(value.slotDuration);
                    setServicePrice(value.price);
                    setAddServiceMode(true);
                  }}
                  onDelete={async () => {
                    const result = await handleDeleteService(key);
                    if (result) {
                      var updatedServices: Record<string, Service> =
                        barberShopInfo?.services ?? {};
                      delete updatedServices[key];
                      const employees = barberShopInfo?.employees || {};
                      Object.values(employees).forEach((employee: any) => {
                        if (
                          employee.services &&
                          employee.services.includes(key)
                        ) {
                          employee.services = employee.services.filter(
                            (service: string) => service !== key
                          );
                        }
                      });
                      setBarberShopInfo(
                        (prevBarberShopInfo: BarberShop | null) => {
                          if (!prevBarberShopInfo) {
                            return null;
                          }
                          return {
                            ...prevBarberShopInfo,
                            services: updatedServices,
                            employees: employees,
                          };
                        }
                      );
                      showSuccessToast("Servizio eliminato");
                    } else {
                      showErrorToast("Si è verificato un errore");
                    }
                  }}
                />
              ))
          )}
        </div>
        {/* Employees */}
        <div className="rounded-xl shadow-lg bg-white p-8 sm:col-span-1">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">Il tuo staff</p>
            <button
              onClick={() => {
                if (addBarberEmployeeMode) {
                  setAddBarberEmployeeMode(false);
                } else {
                  setAddBarberEmployeeMode(true);
                }
              }}
              type="button"
              className="block w-min justify-center rounded-full bg-slate-700 px-2.5 py-2.5 text-sm font-semibold leading-2 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {addBarberEmployeeMode ? (
                <ArrowUturnLeftIcon className="h-4 text-white" />
              ) : (
                <PlusIcon className="h-4 text-white" />
              )}
            </button>
          </div>
          {addBarberEmployeeMode ? (
            <AddBarberEmployeeComponent
              previousBarberName={barberToEdit}
              allServices={Object.entries(barberShopInfo?.services ?? {})
                .sort()
                .map(([key]) => key)}
              previousServices={barberServiceToEdit}
              previousShifts={barberShiftsToEdit}
              setAddBarberModeCallback={(employee: BarberEmployee | null) => {
                const currentEmployees = barberShopInfo?.employees ?? {};
                if (employee !== null) {
                  currentEmployees[employee.name] = {
                    services: employee.services,
                    shifts: employee.shifts,
                  };
                  showSuccessToast("Staff salvato");
                }
                setBarberShopInfo((prevBarberShopInfo: BarberShop | null) => {
                  if (!prevBarberShopInfo) {
                    return null;
                  }
                  return {
                    ...prevBarberShopInfo,
                    employees: currentEmployees,
                  };
                });
                setBarberToEdit("");
                setBarberShiftsToEdit({});
                setBarberServiceToEdit([]);
                setAddBarberEmployeeMode(false);
              }}
              repository={repository}
            />
          ) : (barberShopInfo?.employees ?? []).length === 0 ? (
            <NoDataComponent
              text={"Non hai aggiunto ancora nessun membro dello staff"}
            />
          ) : (
            Object.entries(barberShopInfo?.employees ?? {})
              .sort()
              .map(([key, value]) => (
                <BarberEmployeeItem
                  key={key}
                  barberName={key}
                  services={value.services ?? []}
                  onEditClicked={() => {
                    setBarberToEdit(key);
                    setBarberShiftsToEdit(value.shifts ?? {});
                    setBarberServiceToEdit(value.services ?? []);
                    setAddBarberEmployeeMode(true);
                  }}
                  onDelete={async () => {
                    const result = await handleDeleteBarberEmployee(key);
                    if (result) {
                      var updatedEmployees:
                        | Record<string, Record<string, any>>
                        | undefined = barberShopInfo?.employees ?? {};

                      delete updatedEmployees[key];

                      setBarberShopInfo(
                        (prevBarberShopInfo: BarberShop | null) => {
                          if (!prevBarberShopInfo) {
                            return null;
                          }
                          return {
                            ...prevBarberShopInfo,
                            employees: updatedEmployees,
                          };
                        }
                      );
                      showSuccessToast("Membro dello staff eliminato");
                    } else {
                      showErrorToast("Si è verificato un errore");
                    }
                  }}
                />
              ))
          )}
        </div>
      </div>
      {subExpirationMessage !== "" && (
        <CommunicationAlertView
          text={subExpirationMessage}
          onDismiss={() => {
            setSubExpirationMessage("");
          }}
        />
      )}
    </div>
  );
}

import {
  BarberEmployee,
  BarberShop,
  Promotion,
  Service,
  Slot,
} from "../../Domain/Model";
import { AdminBarberRepository } from "../../Domain/Repository";
import {
  serviceSelected,
  barberEmployeeSelected,
  clickOnBookNow,
  slotSelected,
  clickOnCreateAccount,
  clickOnLogin,
  clickOnLogout,
  changeBusinessName,
  changeBusinessAddress,
  addService,
  addBarberEmployee,
  deleteBarberEmployee,
  deleteReservation,
  addPromotion,
  deletePromotion,
  updateSubscriptionInfo,
} from "../../Domain/UseCase";
import { cancelSubscription } from "../../Domain/UseCase/Admin/CancelSubscriptionUseCase";
import { changeBreakTime } from "../../Domain/UseCase/Admin/ChangeBreakTimeUseCase";
import { changeCommunication } from "../../Domain/UseCase/Admin/ChangeCommunicationUseCase";
import { changeFullName } from "../../Domain/UseCase/Admin/ChangeFullNameUseCase";
import { changePhoneNumber } from "../../Domain/UseCase/Admin/ChangePhoneNumberUseCase";
import { deleteService } from "../../Domain/UseCase/Admin/DeleteServiceUseCase";
import { clickOnResetPassword } from "../../Domain/UseCase/Admin/ResetPasswordUseCase";
export function useAdminBarberShopModelController(
  repository: AdminBarberRepository
) {
  const handleRegistration = async (
    name: string,
    phone: string,
    email: string,
    password: string
  ): Promise<boolean> => {
    return await clickOnCreateAccount(name, phone, email, password, repository);
  };
  const handleLogin = async (
    email: string,
    password: string
  ): Promise<boolean> => {
    return await clickOnLogin(email, password, repository);
  };

  const handleResetPassword = async (email: string) => {
    return await clickOnResetPassword(email, repository);
  };

  const handleSignOut = async (): Promise<boolean> => {
    return await clickOnLogout(repository);
  };

  const handleChangeBusinessName = async (
    businessName: string
  ): Promise<boolean> => {
    return await changeBusinessName(businessName, repository);
  };

  const handleChangeBusinessAddress = async (
    businessAddress: string
  ): Promise<boolean> => {
    return await changeBusinessAddress(businessAddress, repository);
  };

  const handleChangeBreakTime = async (
    startTime: string,
    endTime: string
  ): Promise<boolean> => {
    return await changeBreakTime(startTime, endTime, repository);
  };

  const handleChangeCommunication = async (
    communication: string
  ): Promise<boolean> => {
    return await changeCommunication(communication, repository);
  };

  const handleAddService = async (service: Service): Promise<boolean> => {
    return await addService(service, repository);
  };

  const handleDeleteService = async (serviceName: string): Promise<boolean> => {
    return await deleteService(serviceName, repository);
  };

  const handleAddBarberEmployee = async (
    employee: BarberEmployee
  ): Promise<boolean> => {
    return await addBarberEmployee(employee, repository);
  };

  const handleDeleteBarberEmployee = async (
    employeeName: string
  ): Promise<boolean> => {
    return await deleteBarberEmployee(employeeName, repository);
  };

  const handleDeleteReservation = async (
    reservationId: string
  ): Promise<boolean> => {
    return await deleteReservation(reservationId, repository);
  };

  const handleAddPromo = async (
    promotion: Promotion
  ): Promise<string | null> => {
    return await addPromotion(promotion, repository);
  };

  const handleDeletePromo = async (promotionId: string): Promise<boolean> => {
    return await deletePromotion(promotionId, repository);
  };

  const handleChangeFullName = async (fullName: string): Promise<boolean> => {
    return await changeFullName(fullName, repository);
  };

  const handleChangePhoneNumber = async (
    phoneNumber: string
  ): Promise<boolean> => {
    return await changePhoneNumber(phoneNumber, repository);
  };

  const handleUpdateSubscriptionInfo = async (): Promise<boolean> => {
    return await updateSubscriptionInfo(repository);
  };

  const handleCancelSubscription = async (): Promise<boolean> => {
    return await cancelSubscription(repository);
  };

  return {
    handleRegistration,
    handleLogin,
    handleResetPassword,
    handleSignOut,
    handleChangeBusinessName,
    handleChangeBusinessAddress,
    handleChangeBreakTime,
    handleAddService,
    handleDeleteService,
    handleAddBarberEmployee,
    handleDeleteBarberEmployee,
    handleDeleteReservation,
    handleChangeCommunication,
    handleAddPromo,
    handleDeletePromo,
    handleChangeFullName,
    handleChangePhoneNumber,
    handleUpdateSubscriptionInfo,
    handleCancelSubscription,
  };
}

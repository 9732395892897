import { Fragment, useEffect, useState } from "react";
import { BarberRepository } from "../../Domain/Repository";
import { useBarberShopModelController } from "../hook/useBarberShopModelController";
import { BarberEmployee, BarberShop, Service } from "../../Domain/Model";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import logoWhite from "../../images/logo_white.svg";

type BarberShopClientHeaderViewProps = {
  repository: BarberRepository;
  barberInfo: BarberShop | null;
  services: Service[];
  setFullNameCallback: (value: string) => void;
  setPhoneNumberCallback: (value: string) => void;
  setEmailCallback: (value: string) => void;
  setSelectedServiceCallback: (service: Service) => void;
  setSelectedBarberCallback: (barber: string | null) => void;
  fieldReset: boolean;
};

export function BarberShopClientHeaderView({
  repository,
  barberInfo,
  services,
  setFullNameCallback,
  setPhoneNumberCallback,
  setEmailCallback,
  setSelectedServiceCallback,
  setSelectedBarberCallback,
  fieldReset,
}: BarberShopClientHeaderViewProps) {
  const {
    handleServiceSelected,
    handleBarberEmployeeSelected,
    handleSlotSelected,
    handleClickOnBookNow,
  } = useBarberShopModelController(repository);

  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [filteredBarberEmployees, setFilteredBarberEmployees] = useState<
    string[]
  >([]);
  const [selectedBarber, setSelectedBarber] = useState<string | null>(null);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (fieldReset) {
      setFullName("");
      setPhoneNumber("");
      setEmail("");
      setSelectedService(null);
      setSelectedBarber(null);
    }
  }, [fieldReset]);

  const handleServiceChange = async (value: Service | null) => {
    if (value !== null) {
      setSelectedService(value);
      setSelectedServiceCallback(value);
      setSelectedBarber(null);
      setSelectedBarberCallback(null);
      handleServiceSelected(value!);
      let barberEmployees: BarberEmployee[] = await repository.getBarbers();
      barberEmployees = barberEmployees.filter((barber) =>
        barber.services.includes(value!.name)
      );
      setFilteredBarberEmployees(
        barberEmployees.map((barber: BarberEmployee) => barber.name)
      );
    }
  };

  return (
    <div className="block xl:flex py-12 px-8 lg:m-2 rounded-b-3xl lg:rounded-2xl bg-blue-900">
      <img src={logoWhite} alt="gobarber logo" className="h-12 mb-6 xl:h-24" />
      <div className="mx-auto w-full max-w-4xl">
        <h2 className="text-3xl font-semibold  text-white sm:text-3xl">
          {barberInfo?.businessName}
        </h2>
        {barberInfo?.address && (
          <p className="text-sm font-semibold  text-gray-200 sm:text-sm">
            ({barberInfo?.address})
          </p>
        )}
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor="full-name"
              className="block text-sm font-medium leading-6 text-white"
            >
              Nome e Cognome
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="full-name"
                id="full-name"
                autoComplete="given-name"
                value={fullName}
                onChange={(value) => {
                  setFullName(value.target.value);
                  setFullNameCallback(value.target.value);
                }}
                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-white"
            >
              Telefono
            </label>
            <div className="mt-2">
              <input
                type="tel"
                name="phone"
                id="phone"
                autoComplete="tel"
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value.target.value);
                  setPhoneNumberCallback(value.target.value);
                }}
                className="block w-full rounded-md  py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                value={email}
                onChange={(value) => {
                  setEmail(value.target.value);
                  setEmailCallback(value.target.value);
                }}
                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mt-5">
          <div>
            <Listbox
              value={selectedService}
              onChange={(value) => {
                setSelectedService(value);
                handleServiceChange(value);
              }}
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="mt-2 block text-sm font-medium leading-6 text-white">
                    Servizio
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">
                          {selectedService
                            ? `${selectedService.name} (${selectedService.price}€)`
                            : "Seleziona un servizio"}
                        </span>
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        {open ? (
                          <ChevronUpIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                        {services.map((service) => (
                          <Listbox.Option
                            key={service.name}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "bg-indigo-600 text-white"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-3 pr-9"
                              )
                            }
                            value={service}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center justify-between">
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {service.name}
                                  </span>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "pr-4 block truncate"
                                    )}
                                  >
                                    ({service.price}€)
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          {
            <div>
              <Listbox
                value={selectedBarber}
                onChange={(value) => {
                  setSelectedBarber(value);
                  setSelectedBarberCallback(value);
                  handleBarberEmployeeSelected(value!);
                }}
                disabled={selectedService === null}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="mt-2 block text-sm font-medium leading-6 text-white">
                      Servito da
                    </Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button
                        className={`relative w-full cursor-default rounded-md ${
                          selectedService === null
                            ? "bg-transparent"
                            : "bg-white"
                        } py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6`}
                      >
                        <span className="flex items-center">
                          <span
                            className={`ml-3 block truncate ${
                              selectedService === null
                                ? "text-gray-500"
                                : "text-gray-900"
                            }`}
                          >
                            {selectedBarber ?? "Seleziona un barbiere"}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          {open ? (
                            <ChevronUpIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                          {filteredBarberEmployees.map((barber) => (
                            <Listbox.Option
                              key={barber}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={barber}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {barber}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

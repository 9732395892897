import gobarber_sticker from "../../images/gobarber_sticker.png";
export function LandingStickerComponent() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl pt-24 sm:px-6 sm:pt-48 lg:px-8">
        <div className="grid items-center grid-cols-1 md:grid-cols-2 bg-blue-900 p-12 shadow-2xl sm:rounded-3xl sm:px-16 md:gap-x-6 md:px-8 xl:gap-x-20 xl:px-24">
          <div className="mx-auto text-center lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              In omaggio riceverai l'adesivo GoBarber con il QR code che rimanda
              al tuo negozio.
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Esponilo alla cassa o all'ingresso del tuo negozio in modo che i
              tuoi clienti possano accedere alla pagina per prenotare i tuoi
              servizi direttamente scansionando il QR code.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="/signup"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Inizia subito
              </a>
              <a
                href="#contact"
                className="text-sm font-semibold leading-6 text-white"
              >
                Contattaci <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="w-full mt-16 md:mt-0">
            <img src={gobarber_sticker} alt="App screenshot" />
          </div>
        </div>
      </div>
    </div>
  );
}

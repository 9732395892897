import {
  ArrowTrendingUpIcon,
  BuildingStorefrontIcon,
  MegaphoneIcon,
  QueueListIcon,
} from "@heroicons/react/20/solid";
import whatsapp from "../../images/whatsapp.png";
import facebook from "../../images/facebook.png";
import email from "../../images/mail.png";

const features = [
  {
    name: "WhatsApp",
    description:
      "Scrivici un messaggio su whatsapp o chiamaci direttamente al numero di telefono che trovi nella chat.",
    icon: whatsapp,
    href: "https://api.whatsapp.com/send?phone=393286623047",
  },
  {
    name: "Facebook",
    description: "Contattaci attraverso la nostra pagina Facebook ufficiale.",
    icon: facebook,
    href: "https://www.facebook.com/profile.php?id=61553800143300",
  },
  {
    name: "Email",
    description:
      "Inviaci una email al nostro indirizzo di posta gobarber.info@gmail.com",
    icon: email,
    href: "mailto:gobarber.info@gmail.com",
  },
];

export function LandingContactComponent() {
  return (
    <div id="contact" className="bg-white pt-24 sm:pt-48">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-700">
            Siamo a tua disposizione
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Contattaci
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Hai dubbi o domande? Contattaci con il metodo che preferisci tra
            quelli elencati qui sotto.
          </p>
        </div>
        <div className="mx-auto mt-8 max-w-2xl lg:max-w-4xl">
          <dl className="flex flex-col ">
            {features.map((feature) => (
              <div key={feature.name} className="relative mt-16">
                <dt className="flex text-center font-semibold leading-7 text-gray-900">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-blue-900 mr-6">
                    <img
                      className="h-6 w-6 bg-red-200"
                      style={{
                        filter: "invert(1)",
                        backgroundColor: "transparent",
                      }}
                      src={feature.icon}
                      alt="contact"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 ml-16 leading-7 text-gray-600">
                  {feature.description}
                </dd>
                <a
                  href={feature.href}
                  className="text-sm ml-16 font-semibold leading-6 text-black"
                >
                  Contattaci via {feature.name}
                  <span aria-hidden="true">→</span>
                </a>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

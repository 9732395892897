import { BarberEmployee, Service, Slot } from "../../Domain/Model";
import { BarberRepository } from "../../Domain/Repository";
import {
  serviceSelected,
  barberEmployeeSelected,
  clickOnBookNow,
  slotSelected,
} from "../../Domain/UseCase";
export function useBarberShopModelController(repository: BarberRepository) {
  const handleServiceSelected = async (service: Service) => {
    await serviceSelected(service, repository);
  };

  const handleBarberEmployeeSelected = async (barber: string) => {
    await barberEmployeeSelected(barber, repository);
  };

  const handleSlotSelected = async (slot: Slot | null) => {
    await slotSelected(slot, repository);
  };

  const handleClickOnBookNow = async (
    userName: string,
    userPhone: string,
    userEmail: string,
    repository: BarberRepository
  ): Promise<boolean> => {
    return await clickOnBookNow(userName, userPhone, userEmail, repository);
  };

  return {
    handleServiceSelected,
    handleBarberEmployeeSelected,
    handleSlotSelected,
    handleClickOnBookNow,
  };
}

import logo from "../../images/loader_logo.svg";

export function LoaderView() {
  return (
    <div className="flex w-full h-full overflow-y-hidden bg-white fixed top-0 left-0">
      <img
        className="w-full sm:w-1/3 mx-auto animate-pulse"
        src={logo}
        alt="loader"
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { Promotion } from "../../Domain/Model/Promotion";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { useAuthStatus } from "../hook/useAuthStatus";
import { BarberShop } from "../../Domain/Model";
import { PromoItemComponent } from "../Components/PromoItemComponent";
import { daysOfWeek } from "../../Utils/DateUtil";
import { AddPromoComponent } from "../Components/AddPromoComponent";
import { NoDataComponent } from "../Components/NoDataComponent";
import { showErrorToast, showSuccessToast } from "../../Utils/ToastUtil";
import { ArrowUturnLeftIcon, PlusIcon } from "@heroicons/react/20/solid";
import { DashboardTabViewProps } from "./DashboardView";

export function DashboardPromotionsView({
  repository,
  pageIndex,
  selectedIndex,
}: DashboardTabViewProps) {
  const [addPromoMode, setAddPromoMode] = useState<boolean>(false);
  const [promoId, setPromoId] = useState<string | null>(null);
  const [promoName, setPromoName] = useState<string>("");
  const [promoService, setPromoService] = useState<string>("");
  const [promoDays, setPromoDays] = useState<number[]>([]);
  const [promoStartTime, setPromoStartTime] = useState<string>("9:00");
  const [promoEndTime, setPromoEndTime] = useState<string>("9:30");
  const [discountValue, setDiscountValue] = useState<string>("");
  const [promos, setPromos] = useState<Promotion[]>([]);
  const [allServices, setAllServices] = useState<string[]>([]);
  const { userEmail } = useAuthStatus();
  const { handleDeletePromo } = useAdminBarberShopModelController(repository);

  useEffect(() => {
    if (userEmail !== null) {
      loadInfo();
    }
  }, [userEmail]);

  useEffect(() => {
    // Used to reload page information when this is the active tab
    if (selectedIndex === pageIndex && userEmail !== null) {
      loadInfo();
    }
  }, [selectedIndex]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      const info = await repository.getBarberShopInfo(userEmail);
      if (info) {
        const services: string[] = [];
        Object.entries(info.services ?? [])
          .sort()
          .map(([key, value]) => services.push(key));
        setAllServices(services);
        const promotions = await repository.getPromotions();
        setPromos(promotions.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
  };
  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Le tue promozioni
            </p>
            <button
              onClick={() => {
                if (addPromoMode) {
                  setPromoId(null);
                  setPromoName("");
                  setPromoService("");
                  setPromoDays([]);
                  setPromoStartTime("9:00");
                  setPromoEndTime("9:30");
                  setDiscountValue("");
                  setAddPromoMode(false);
                } else {
                  setAddPromoMode(true);
                }
              }}
              type="button"
              className="block w-min justify-center rounded-full bg-slate-700 px-2.5 py-2.5 text-sm font-semibold leading-2 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {addPromoMode ? (
                <ArrowUturnLeftIcon className="h-4 text-white" />
              ) : (
                <PlusIcon className="h-4 text-white" />
              )}
            </button>
          </div>
          {addPromoMode ? (
            <AddPromoComponent
              previousPromoId={promoId}
              previousPromoName={promoName}
              previousServiceName={promoService}
              allServices={allServices}
              previousDays={promoDays}
              previousStartTime={promoStartTime}
              previousEndTime={promoEndTime}
              previousDiscountValue={discountValue}
              setAddPromoModeCallback={(promo: Promotion | null) => {
                setPromoId(null);
                setPromoName("");
                setPromoService("");
                setPromoDays([]);
                setPromoStartTime("9:00");
                setPromoEndTime("9:30");
                setDiscountValue("");
                setAddPromoMode(false);
                if (promo) {
                  const existingPromoIndex = promos.findIndex(
                    (p) => p.id === promo.id
                  );

                  if (existingPromoIndex !== -1) {
                    const newPromos = [...promos];
                    newPromos[existingPromoIndex] = promo;
                    setPromos(
                      newPromos.sort((a, b) => a.name.localeCompare(b.name))
                    );
                    showSuccessToast("Promozione aggiornata");
                  } else {
                    const newPromos = [...promos, promo];
                    setPromos(
                      newPromos.sort((a, b) => a.name.localeCompare(b.name))
                    );
                    showSuccessToast("Promozione salvata");
                  }
                }
              }}
              repository={repository}
            />
          ) : promos.length === 0 ? (
            <NoDataComponent
              text={"Non hai aggiunto ancora nessuna promozione"}
            />
          ) : (
            promos.sort().map((promo) => (
              <PromoItemComponent
                key={promo.id}
                reservationId={promo.id ?? ""}
                name={promo.name}
                service={promo.service}
                days={promo.days.map((number) => daysOfWeek[number])}
                formattedTime={`${promo.startTime}-${promo.endTime}`}
                discountValue={promo.discountValue}
                onEditClicked={() => {
                  setPromoId(promo.id);
                  setPromoName(promo.name);
                  setPromoService(promo.service);
                  setPromoDays(promo.days);
                  setPromoStartTime(promo.startTime);
                  setPromoEndTime(promo.endTime);
                  setDiscountValue(promo.discountValue);
                  setAddPromoMode(true);
                }}
                onDelete={async () => {
                  if (promo.id) {
                    const result = await handleDeletePromo(promo.id);
                    if (result) {
                      const updatedPromos = promos.filter(
                        (element) => element.id !== promo.id
                      );
                      setPromos(updatedPromos);
                      showSuccessToast("Promozione eliminata");
                    } else {
                      showErrorToast("Si è verificato un errore");
                    }
                  }
                }}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

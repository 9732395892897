import {
  Service,
  BarberEmployee,
  Reservation,
  BarberShop,
  Promotion,
} from "../../Domain/Model";
import { AdminBarberRepository } from "../../Domain/Repository/AdminBarberRepository";
import { AdminBarberDataSource } from "../DataSource/AdminBarberDataSource";

export class AdminBarberRepositoryImpl implements AdminBarberRepository {
  dataSource: AdminBarberDataSource;
  constructor(dataSource: AdminBarberDataSource) {
    this.dataSource = dataSource;
  }

  createAccount(
    name: string,
    phone: string,
    email: string,
    password: string
  ): Promise<boolean> {
    return this.dataSource.createAccount(name, phone, email, password);
  }
  login(email: string, password: string): Promise<boolean> {
    return this.dataSource.login(email, password);
  }
  resetPassword(email: string): Promise<boolean> {
    return this.dataSource.resetPassword(email);
  }
  async signOut(): Promise<boolean> {
    return this.dataSource.signOut();
  }
  async setBarberShopName(name: string): Promise<boolean> {
    return await this.dataSource.setBarberShopName(name);
  }
  async setBarberShopAddress(address: string): Promise<boolean> {
    return await this.dataSource.setBarberShopAddress(address);
  }
  async addService(service: Service): Promise<boolean> {
    return await this.dataSource.addService(service);
  }
  async deleteService(serviceName: string): Promise<boolean> {
    return await this.dataSource.deleteService(serviceName);
  }
  async addBarberEmployee(employee: BarberEmployee): Promise<boolean> {
    return await this.dataSource.addBarberEmployee(employee);
  }
  async deleteBarberEmployee(employeeName: string): Promise<boolean> {
    return await this.dataSource.deleteBarberEmployee(employeeName);
  }
  async setBreakTime(startTime: string, endTime: string): Promise<boolean> {
    return await this.dataSource.setBreakTime(startTime, endTime);
  }
  async setCommunication(message: string): Promise<boolean> {
    return await this.dataSource.setCommunication(message);
  }
  async loadAppointments(
    onReservationsUpdated: (reservations: Reservation[]) => void
  ): Promise<void> {
    await this.dataSource.loadAppointments(onReservationsUpdated);
  }
  async getLoadedAppointments(): Promise<Reservation[]> {
    return await this.dataSource.getLoadedAppointments();
  }
  async deleteReservation(reservationId: string): Promise<boolean> {
    return await this.dataSource.deleteReservation(reservationId);
  }
  async getBarberShopInfo(email: string): Promise<BarberShop | null> {
    return await this.dataSource.getBarberShopInfo(email);
  }
  async getBarberShopLink(): Promise<string> {
    return await this.dataSource.getBarberShopLink();
  }
  async getPromotions(): Promise<Promotion[]> {
    return await this.dataSource.getPromotions();
  }
  async setPromotion(promotion: Promotion): Promise<string | null> {
    return await this.dataSource.setPromotion(promotion);
  }
  async deletePromotion(promotionId: string): Promise<boolean> {
    return await this.dataSource.deletePromotion(promotionId);
  }
  async setFullName(fullName: string): Promise<boolean> {
    return await this.dataSource.setFullName(fullName);
  }
  async setPhoneNumber(phoneNumber: string): Promise<boolean> {
    return await this.dataSource.setPhoneNumber(phoneNumber);
  }
  async updateSubscriptionInfo(): Promise<boolean> {
    return await this.dataSource.updateSubscriptionInfo();
  }
  async getCheckoutSessionAndUpdateSubscriptionInfo(
    checkoutId: string
  ): Promise<boolean> {
    return await this.dataSource.getCheckoutSessionAndUpdateSubscriptionInfo(
      checkoutId
    );
  }
  async cancelSubscription(): Promise<boolean> {
    return await this.dataSource.cancelSubscription();
  }
}

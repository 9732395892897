import { useEffect, useState } from "react";
import { useAuthStatus } from "../hook/useAuthStatus";
import { Reservation } from "../../Domain/Model";
import { getItalianMonthInitial } from "../../Utils/DateUtil";
import { DashboardTabViewProps } from "./DashboardView";
import { AnalyticsChartComponent } from "../Components/AnalyticsChartComponent";

export function DashboardAnalyticsView({
  repository,
  pageIndex,
  selectedIndex,
}: DashboardTabViewProps) {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const { userEmail } = useAuthStatus();

  useEffect(() => {
    if (userEmail !== null) {
      loadInfo();
    }
  }, [userEmail]);

  useEffect(() => {
    // Used to reload page information when this is the active tab
    if (selectedIndex === pageIndex && userEmail !== null) {
      loadInfo();
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (reservations.length > 0) {
      generateChartData();
    }
  }, [reservations]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      const info = await repository.getBarberShopInfo(userEmail);
      const appointments = await repository.getLoadedAppointments();
      setReservations(appointments);
    }
  };

  const generateChartData = () => {
    console.log(reservations);
    const currentDate = new Date();
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 2,
      0 // last day of the next month
    );
    endDate.setHours(23, 59, 59, 999);

    const startDate = new Date(endDate);
    startDate.setMonth(endDate.getMonth() - 12);

    const monthlyStats: { [key: string]: number } = {};

    const allMonths = [];
    let currentMonth = new Date(startDate);
    while (currentMonth <= endDate) {
      allMonths.push({
        monthKey: `${getItalianMonthInitial(
          currentMonth.getMonth()
        )}-${currentMonth.getFullYear()}`,
        month: `${getItalianMonthInitial(
          currentMonth.getMonth()
        )} ${currentMonth.getFullYear()}`,
      });
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }

    reservations.forEach((reservation) => {
      const reservationDate = new Date(reservation.slotStartTime);

      if (reservationDate >= startDate && reservationDate <= endDate) {
        const monthKey = `${getItalianMonthInitial(
          reservationDate.getMonth()
        )}-${reservationDate.getFullYear()}`;
        if (!monthlyStats[monthKey]) {
          monthlyStats[monthKey] = 0;
        }

        monthlyStats[monthKey]++;
      } else {
        console.log(reservationDate);
        console.log(endDate);
      }
    });

    const chartData = allMonths.map((monthInfo) => ({
      reservations: monthlyStats[monthInfo.monthKey] || 0,
      month: monthInfo.month,
    }));

    setChartData(chartData);
  };

  const getTop5RequestedServices = (
    reservations: Reservation[]
  ): Map<string, number> => {
    const serviceCounts = new Map<string, number>();

    reservations.forEach((reservation) => {
      const serviceName = reservation.service.name;
      if (serviceCounts.has(serviceName)) {
        serviceCounts.set(serviceName, serviceCounts.get(serviceName)! + 1);
      } else {
        serviceCounts.set(serviceName, 1);
      }
    });

    const sortedServices = new Map(
      [...serviceCounts.entries()].sort((a, b) => b[1] - a[1])
    );
    return new Map([...sortedServices.entries()].slice(0, 5));
  };

  const getTop5FrequentCustomers = (
    reservations: Reservation[]
  ): Map<string, number> => {
    const customerCounts = new Map<string, number>();

    reservations.forEach((reservation) => {
      const customerPhone = reservation.customerPhone;
      if (customerCounts.has(customerPhone)) {
        customerCounts.set(
          customerPhone,
          customerCounts.get(customerPhone)! + 1
        );
      } else {
        customerCounts.set(customerPhone, 1);
      }
    });

    const sortedCustomers = new Map(
      [...customerCounts.entries()].sort((a, b) => b[1] - a[1])
    );

    return new Map([...sortedCustomers.entries()].slice(0, 5));
  };

  const getMostBookedSlot = (reservations: Reservation[]): string => {
    const slotCounts = new Map<string, number>();

    reservations.forEach((reservation) => {
      const slot = reservation.formattedString;
      if (slotCounts.has(slot)) {
        slotCounts.set(slot, slotCounts.get(slot)! + 1);
      } else {
        slotCounts.set(slot, 1);
      }
    });

    if (reservations.length === 0) {
      return "-";
    }

    const mostBookedSlot = [...slotCounts.entries()].reduce((a, b) =>
      a[1] > b[1] ? a : b
    );

    return mostBookedSlot[0];
  };

  const getMostBookedDayTime = (reservations: Reservation[]): string => {
    const dayTimeCounts = new Map<string, number>();
    const dayTimeToString: Record<string, string> = {
      morning: "Mattina",
      lunch: "Pranzo",
      afternoon: "Pomeriggio",
      evening: "Sera",
    };

    reservations.forEach((reservation) => {
      const dayTime = reservation.dayTime;
      if (dayTimeCounts.has(dayTime)) {
        dayTimeCounts.set(dayTime, dayTimeCounts.get(dayTime)! + 1);
      } else {
        dayTimeCounts.set(dayTime, 1);
      }
    });

    if (reservations.length === 0) {
      return "-";
    }
    const mostBookedDayTime = [...dayTimeCounts.entries()].reduce((a, b) =>
      a[1] > b[1] ? a : b
    );

    return dayTimeToString[mostBookedDayTime[0]];
  };

  const getMostBookedEmployee = (reservations: Reservation[]): string => {
    const employeeCounts = new Map<string, number>();

    reservations.forEach((reservation) => {
      const employee = reservation.assignedTo;
      if (employeeCounts.has(employee)) {
        employeeCounts.set(employee, employeeCounts.get(employee)! + 1);
      } else {
        employeeCounts.set(employee, 1);
      }
    });

    if (reservations.length === 0) {
      return "-";
    }
    const mostBookedEmployee = [...employeeCounts.entries()].reduce((a, b) =>
      a[1] > b[1] ? a : b
    );

    return mostBookedEmployee[0];
  };

  const getDayWithMostAverageBookings = (
    reservations: Reservation[]
  ): string => {
    const dayCounts = new Map<string, number>();
    const daysOfWeek = [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
    ];

    reservations.forEach((reservation) => {
      const timestamp = reservation.slotStartTime;
      const date = new Date(timestamp);
      const day = date.getDay();
      const dayName = daysOfWeek[day];

      if (dayCounts.has(dayName)) {
        dayCounts.set(dayName, dayCounts.get(dayName)! + 1);
      } else {
        dayCounts.set(dayName, 1);
      }
    });

    if (reservations.length === 0) {
      return "-";
    }
    const mostBookedDay = [...dayCounts.entries()].reduce((a, b) =>
      a[1] > b[1] ? a : b
    );

    return mostBookedDay[0];
  };

  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">Statistiche</p>
          </div>
          <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6">
            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                Numero di prenotazioni totali
              </p>
              <p className="text-black text-center">{reservations.length}</p>
            </div>

            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                Il dipendente più richiesto
              </p>

              <p className="text-black text-center">
                {getMostBookedEmployee(reservations)}
              </p>
            </div>

            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                Il giorno più affollato
              </p>
              <p className="text-black text-center">
                {getDayWithMostAverageBookings(reservations)}
              </p>
            </div>
            <div>
              <p className="text-gray-600 text-center mb-2 font-semibold">
                L'orario più richiesto
              </p>

              <p className="text-black text-center">
                {getMostBookedSlot(reservations)}
              </p>
            </div>
            <div>
              <p className="text-gray-600 text-center font-semibold mb-2">
                La fascia oraria più affollata
              </p>

              <p className="text-black text-center">
                {getMostBookedDayTime(reservations)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-8">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Top 5 clienti
            </p>
          </div>
          {getTop5FrequentCustomers(reservations).size === 0 ? (
            <p className="text-black text-center">-</p>
          ) : (
            <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6">
              {[...getTop5FrequentCustomers(reservations).entries()].map(
                (customer) => (
                  <div key={customer[0]} className="text-center">
                    <p className="text-black">
                      {
                        reservations.find(
                          (reservation) =>
                            reservation.customerPhone === customer[0]
                        )?.customerName
                      }{" "}
                      {<span className="text-gray-600 ">({customer[1]})</span>}
                    </p>
                    <p className="text-black  text-sm mb-2">({customer[0]})</p>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-8">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Top 5 servizi
            </p>
          </div>
          {getTop5RequestedServices(reservations).size === 0 ? (
            <p className="text-black text-center">-</p>
          ) : (
            <div className="mx-auto w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6">
              {[...getTop5RequestedServices(reservations).entries()].map(
                (service) => (
                  <p key={service[0]} className="text-black text-center">
                    {service[0] ?? "-"}{" "}
                    {
                      <span className="text-gray-600 text-center">
                        ({service[1]})
                      </span>
                    }
                  </p>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-8">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">
              Andamento mensile prenotazioni
            </p>
          </div>
          <AnalyticsChartComponent data={chartData} />
        </div>
      </div>
    </div>
  );
}

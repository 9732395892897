import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { useState, Fragment } from "react";
import { BarberShop, Promotion, Service } from "../../Domain/Model";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { AdminBarberRepository } from "../../Domain/Repository";
import {
  daysOfWeek,
  daysOfWeekNumber,
  daysOfWeekString,
  slots,
} from "../../Utils/DateUtil";

type AddPromoComponentProps = {
  previousPromoId: string | null;
  previousPromoName: string;
  previousServiceName: string;
  allServices: string[];
  previousDays: number[];
  previousStartTime: string;
  previousEndTime: string;
  previousDiscountValue: string;
  setAddPromoModeCallback: (promo: Promotion | null) => void;
  repository: AdminBarberRepository;
};

export function AddPromoComponent({
  previousPromoId,
  previousPromoName,
  previousServiceName,
  allServices,
  previousDays,
  previousStartTime,
  previousEndTime,
  previousDiscountValue,
  setAddPromoModeCallback,
  repository,
}: AddPromoComponentProps) {
  const [promoName, setPromoName] = useState<string>(previousPromoName);
  const [serviceName, setServiceName] = useState<string>(previousServiceName);
  const [promoDays, setPromoDays] = useState<number[]>(previousDays);
  const [promoStartTime, setPromoStartTime] =
    useState<string>(previousStartTime);
  const [promoEndTime, setPromoEndTime] = useState<string>(previousEndTime);
  const [discountValue, setDiscountValue] = useState<string>(
    previousDiscountValue
  );
  const { handleAddPromo } = useAdminBarberShopModelController(repository);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const handleAddRemoveDay = (day: number) => {
    var newDays = [];
    if (promoDays.includes(day)) {
      newDays = promoDays.filter((element) => element !== day);
    } else {
      newDays = [...promoDays];
      newDays.push(day);
    }
    setPromoDays(newDays);
  };
  return (
    <div>
      <div>
        <label
          htmlFor="serviceName"
          className="mt-5 block text-sm font-medium leading-6 text-black"
        >
          Nome della promozione
        </label>
        <div className="mt-2">
          <input
            id="serviceName"
            name="serviceName"
            type="text"
            value={promoName}
            onChange={(e) => setPromoName(e.target.value)}
            required
            className="block w-full sm:w-2/3 rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
          />
        </div>
      </div>
      <Listbox
        value={serviceName}
        onChange={(value) => {
          setServiceName(value);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-black">
              Servizio
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full sm:w-2/3 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate">
                    {serviceName !== "" ? serviceName : "Seleziona un servizio"}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {open ? (
                    <ChevronUpIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full sm:w-2/3 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                  {allServices.map((service, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={service}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center justify-between">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {service}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div className="mt-10 space-y-10">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900">
            Giorni
          </legend>
          {daysOfWeekString.map((day, index) => (
            <div className="mt-6 space-y-6">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="value"
                    name="value"
                    type="checkbox"
                    checked={promoDays.includes(daysOfWeekNumber[index])}
                    onChange={() => {
                      handleAddRemoveDay(daysOfWeekNumber[index]);
                    }}
                    className="h-4 w-4 rounded border-gray-300 accent-slate-700"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="value" className="font-medium text-gray-900">
                    {day}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </fieldset>
        <div className="block mt-5">
          <div className="mb-3">Inizia alle</div>
          <div className="block mb-5">
            <div className="mb-3 w-full sm:w-1/3">
              <Listbox
                value={promoStartTime}
                onChange={(value) => setPromoStartTime(value)}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Orario inizio turno
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {promoStartTime}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                          {slots.slice(1).map((time, idx) => (
                            <Listbox.Option
                              key={time}
                              value={time}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center justify-between">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {time}
                                    </span>
                                  </div>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div className="mb-3">Termina alle</div>
            <div className="mb-3 w-full sm:w-1/3">
              <Listbox
                value={promoEndTime}
                disabled={
                  promoStartTime === "" ||
                  promoStartTime === null ||
                  promoStartTime === undefined ||
                  promoStartTime === slots[0]
                }
                onChange={(value) => setPromoEndTime(value)}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Orario fine turno
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {promoEndTime}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                          {slots
                            .slice(slots.indexOf(promoStartTime) + 1)
                            .map((time) => (
                              <Listbox.Option
                                key={time}
                                value={time}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center justify-between">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {time}
                                      </span>
                                    </div>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="promoDiscount"
            className="mt-5 block text-sm font-medium leading-6 text-black"
          >
            Sconto
          </label>
          <div className="relative mt-2 mx-auto rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">%</span>
            </div>
            <input
              id="promoDiscount"
              name="promoDiscount"
              type="number"
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
              placeholder="0"
              required
              className="block w-1/3 sm:w-1/5 rounded-md border-0 py-1.5 !pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
            />
          </div>
        </div>
        <button
          disabled={serviceName === ""}
          className={`mt-4 flex items-center justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            promoName !== "" &&
            serviceName !== "" &&
            promoDays.length > 0 &&
            discountValue !== ""
              ? "cursor-default"
              : "cursor-not-allowed opacity-40"
          }`}
          onClick={async () => {
            var promo: Promotion = {
              id: previousPromoId,
              name: promoName,
              service: serviceName,
              days: promoDays,
              startTime: promoStartTime,
              endTime: promoEndTime,
              discountValue: discountValue,
            };
            const result = await handleAddPromo(promo);
            if (result !== null) {
              promo = { ...promo, id: result };
            }
            setAddPromoModeCallback(result !== null ? promo : null);
            setPromoName("");
            setServiceName("");
            setPromoDays([]);
            setPromoStartTime("9:00");
            setPromoEndTime("9:30");
            setDiscountValue("");
          }}
        >
          Salva
        </button>
      </div>
    </div>
  );
}

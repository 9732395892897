import { useEffect, useState } from "react";
import { AdminBarberRepository } from "../../Domain/Repository";
import { useAuthStatus } from "../hook/useAuthStatus";
import QRCode from "react-qr-code";
import { DashboardTabViewProps } from "./DashboardView";

export function DashboardSharingView({ repository }: DashboardTabViewProps) {
  const [shopUrl, setShopUrl] = useState<string>("");
  const { userEmail } = useAuthStatus();

  useEffect(() => {
    loadInfo();
  }, [userEmail]);

  const loadInfo = async () => {
    if (userEmail !== null) {
      await repository.getBarberShopInfo(userEmail);
      const shopUrl = await repository.getBarberShopLink();
      setShopUrl(shopUrl);
    }
  };

  return (
    <div className="mx-auto w-full h-full min-h-screen bg-neutral-100 block py-6 px-4 md:px-8">
      <div className="flex w-full items-center justify-center">
        <div className="rounded-xl shadow-lg bg-white p-8 w-full md:w-4/5 lg:w-2/3 justify-center items-center">
          <div className="flex flex-1 mb-8 items-center justify-between text-sm">
            <p className="text-black text-2xl font-bold w-full">I tuoi link</p>
          </div>
          <div className="mx-auto w-full grid grid-cols-1 gap-y-6">
            <div className="text-center">
              <p className="text-gray-600 text-center font-semibold mb-2">
                Link per prenotare
              </p>
              <a
                href={shopUrl}
                target="_blank"
                className="mx-auto text-black text-center break-words"
              >
                {shopUrl}
              </a>
            </div>
            <div className="">
              <p className="text-gray-600 text-center mb-2 font-semibold">
                Il tuo QrCode
              </p>
              <QRCode className="mx-auto mt-6" value={shopUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

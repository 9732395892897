import { useEffect, useRef, useState } from "react";
import { BarberShop, DateInfo, Service, Slot } from "../../Domain/Model";
import { BarberRepository } from "../../Domain/Repository";
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import morning from "../../images/morning.png";
import noon from "../../images/noon.png";
import afternoon from "../../images/afternoon.png";
import evening from "../../images/evening.png";
import lockedCalendar from "../../images/locked_calendar.png";
import {
  addMonths,
  endOfMonth,
  startOfMonth,
  eachDayOfInterval,
} from "date-fns";
import { dateSelected } from "../../Domain/UseCase/Client/DaySelectedUseCase";
import { useBarberShopModelController } from "../hook/useBarberShopModelController";
import { NoSlotsAvailable } from "../Components/NoSlotsAvailable";

type BarberShopClientCalendarViewProps = {
  repository: BarberRepository;
  slotDuration: number;
  selectedBarber: string | null;
  setSelectedSlotCallback: (slot: Slot | null) => void;
};

export function BarberShopClientCalendarView({
  repository,
  slotDuration,
  selectedBarber,
  setSelectedSlotCallback,
}: BarberShopClientCalendarViewProps) {
  const { handleSlotSelected } = useBarberShopModelController(repository);

  const [selectedDay, setSelectedDate] = useState<DateInfo | null>();
  const daysContainerRef = useRef<HTMLDivElement | null>(null);
  const [slots, setSlots] = useState<Slot[] | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [days, setDays] = useState<DateInfo[]>([]);

  useEffect(() => {
    if (selectedBarber !== null) {
      initializeCalendar(null);
    } else {
      setSlots(null);
    }
  }, [selectedBarber]);

  useEffect(() => {
    if (selectedDay !== undefined && selectedDay !== null) {
      updateSlots(selectedDay);
    }
  }, [selectedDay]);

  const updateSlots = async (day: DateInfo) => {
    const slots: Slot[] = await dateSelected(day, repository);
    setSelectedSlot(null);
    handleSlotSelected(null);
    setSlots(slots);
    setSelectedSlotCallback(null);
  };

  const initializeCalendar = async (date: Date | null) => {
    const today = new Date();
    const currentDate = date ?? today;
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const daysInMonth = new Date(year, month, 0).getDate();
    const listOfDays: DateInfo[] = [];

    const isPastDate = currentDate.getTime() < today.getTime();
    const isFutureMonth =
      (date !== null && date.getMonth() > today.getMonth()) ||
      (date !== null && date.getFullYear() > today.getFullYear());
    var startingDay = isFutureMonth ? 1 : today.getDate();

    for (let i = startingDay; i <= daysInMonth; i++) {
      if (i <= daysInMonth) {
        const date = new Date(year, month - 1, i);
        const dayName = date.toLocaleString("default", { weekday: "short" });
        const monthName = date.toLocaleString("default", { month: "long" });
        listOfDays.push({
          weekDayNumber: date.getDay(),
          dayNumber: i,
          dayName,
          monthNumber: month,
          monthName,
          year,
          timestamp:
            i === new Date().getTime() ? new Date().getTime() : date.getTime(),
        });
      } else {
        break;
      }
    }
    if (date === null) {
      setSelectedDate(listOfDays[0]);
    } else {
      setSelectedDate({
        weekDayNumber: isPastDate ? today.getDay() : date.getDay(),
        dayNumber: isPastDate ? today.getDate() : date.getDate(),
        dayName: isPastDate
          ? today.toLocaleString("default", { weekday: "short" })
          : date.toLocaleString("default", { weekday: "short" }),
        monthNumber: month,
        monthName: date.toLocaleString("default", { month: "long" }),
        year: date.getFullYear(),
        timestamp: date.getTime(),
      });
    }
    setDays(listOfDays);
    updateSlots(
      date === null
        ? listOfDays[0]
        : {
            weekDayNumber: date.getDay(),
            dayNumber: date.getDate(),
            dayName: date.toLocaleString("default", { weekday: "short" }),
            monthNumber: month,
            monthName: date.toLocaleString("default", { month: "long" }),
            year: date.getFullYear(),
            timestamp: date.getTime(),
          }
    );
  };
  const changeMonth = async (forward: boolean) => {
    const selectedDate = new Date(
      selectedDay!.year,
      selectedDay!.monthNumber - 1,
      1
    );
    const newDate = forward
      ? addMonths(selectedDate, 1)
      : addMonths(selectedDate, -1);
    initializeCalendar(newDate);
  };

  const scrollList = (
    element: HTMLDivElement | null,
    scrollForward: boolean
  ) => {
    const step = scrollForward ? 10 : -10;
    const distance = 100;
    const speed = 25;
    let scrollAmount = 0;
    if (element) {
      const slideTimer = setInterval(() => {
        element!.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
      }, speed);
    }
  };

  if (selectedBarber === null) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center content-center">
        <img src={lockedCalendar} width={150} alt="locked-calendar" />
        <p className="mt-5 py-5 px-4 text-xl font-normal text-center  text-gray-700 sm:text-xl">
          E' necessario selezionare il servizio e il barbiere che preferisci per
          accedere al calendario
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 items-center justify-center mb-10">
      <div className="flex items-center">
        {((selectedDay?.monthNumber ?? new Date().getMonth() + 1) >
          new Date().getMonth() + 1 ||
          (selectedDay?.year ?? new Date().getFullYear()) >
            new Date().getFullYear()) && (
          <ChevronLeftIcon
            onClick={() => changeMonth(false)}
            className="mr-2 h-6 w-6 text-black-600"
            aria-hidden="true"
          />
        )}
        <p className="text-xl font-semibold  text-gray-700 sm:text-xl capitalized">
          {selectedDay?.monthName} {selectedDay?.year}
        </p>
        <ChevronRightIcon
          onClick={() => changeMonth(true)}
          className="ml-2 h-6 w-6 text-black-600"
          aria-hidden="true"
        />
      </div>
      <div className="mx-auto flex w-full justify-center items-center">
        <ChevronLeftIcon
          onClick={() => scrollList(daysContainerRef.current, false)}
          className="ml-1 mr-2 h-6 w-6 text-black-600"
          aria-hidden="true"
        />
        <div
          ref={(ref) => (daysContainerRef.current = ref)}
          className="flex overflow-x-auto no-scrollbar space-x-5 w-10/12 md:w-4/6 lg:w-1/3 items-center"
        >
          {days.map((day) => (
            <div
              key={day.timestamp}
              onClick={() => {
                initializeCalendar(new Date(day.timestamp));
              }}
              className={`flex-none py-6 px-3 day-circle cursor-pointer ${
                selectedDay?.dayNumber === day.dayNumber
                  ? "day-circle-selected h-[60px] w-[60px]"
                  : "day-circle h-[50px] w-[50px]"
              }`}
            >
              <div className="flex flex-col items-center justify-center">
                <p
                  className={`${
                    selectedDay?.dayNumber === day.dayNumber
                      ? "dark:text-white text-white"
                      : "text-black"
                  } text-xs font-medium capitalized`}
                >
                  {day.dayName}
                </p>
                <p
                  className={`${
                    selectedDay?.dayNumber === day.dayNumber
                      ? "dark:text-white text-white text-xl"
                      : "text-black text-sm"
                  } font-medium capitalized`}
                >
                  {day.dayNumber}
                </p>
              </div>
            </div>
          ))}
        </div>
        <ChevronRightIcon
          onClick={() => scrollList(daysContainerRef.current, true)}
          className="mr-1 ml-2 h-6 w-6 text-black-600"
          aria-hidden="true"
        />
      </div>

      <div className="mt-3 flex items-center">
        <CalendarDaysIcon height={20} />
        <p className="ml-1 text-xs font-semibold  text-gray-700 sm:text-xs capitalized">
          {selectedDay?.dayName} {selectedDay?.dayNumber}{" "}
          {selectedDay?.monthName} {selectedDay?.year}
        </p>
      </div>

      {slots?.length === 0 ? (
        <div className="py-6">
          <NoSlotsAvailable />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-x-20 gap-y-16 text-center md:grid-cols-4">
          <div className="block space-y-8 w-10/12 md:w-4/6 lg:w-1/3 items-center">
            <div className="mt-3 flex justify-center items-center day-time-container">
              <img src={morning} width={30} alt="morning" />
              <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                Mattina
              </p>
            </div>
            {slots
              ?.filter((slot) => slot.dayTime === "morning")
              ?.map((slot) => (
                <div
                  key={slot.timestampStart}
                  onClick={
                    slot?.isFree
                      ? () => {
                          setSelectedSlot(slot);
                          handleSlotSelected(slot);
                          setSelectedSlotCallback(slot);
                        }
                      : () => {}
                  }
                  className={`flex py-3 px-4 ${
                    selectedSlot?.timestampStart === slot.timestampStart
                      ? "slot-box-active"
                      : "slot-box"
                  } ${
                    slot?.isFree
                      ? "cursor-pointer"
                      : "slot-box-not-available cursor-not-allowed"
                  }`}
                >
                  {slot.formattedString}

                  {slot.discount && (
                    <span className="discount-strip">
                      -{slot.discountValue}
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div
            className="block space-y-8 w-10/12 md:w-4/6 lg:w-1/3 items-center"
            ref={daysContainerRef}
          >
            <div className="mt-3 flex justify-center items-center day-time-container">
              <img src={noon} width={30} alt="noon" />
              <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                Pranzo
              </p>
            </div>
            {slots
              ?.filter((slot) => slot.dayTime === "lunch")
              ?.map((slot) => (
                <div
                  key={slot.timestampStart}
                  onClick={
                    slot?.isFree
                      ? () => {
                          setSelectedSlot(slot);
                          handleSlotSelected(slot);
                          setSelectedSlotCallback(slot);
                        }
                      : () => {}
                  }
                  className={`flex py-3 px-4 ${
                    selectedSlot?.timestampStart === slot.timestampStart
                      ? "slot-box-active"
                      : "slot-box"
                  } ${
                    slot?.isFree
                      ? "cursor-pointer"
                      : "slot-box-not-available cursor-not-allowed"
                  }`}
                >
                  {slot.formattedString}
                  {slot.discount && (
                    <span className="discount-strip">
                      -{slot.discountValue}
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div
            className="block space-y-8 w-10/12 md:w-4/6 lg:w-1/3 items-center"
            ref={daysContainerRef}
          >
            <div className="mt-3 flex justify-center items-center day-time-container">
              <img src={afternoon} width={30} alt="afternoon" />
              <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                Pomeriggio
              </p>
            </div>
            {slots
              ?.filter((slot) => slot.dayTime === "afternoon")
              ?.map((slot) => (
                <div
                  key={slot.timestampStart}
                  onClick={
                    slot?.isFree
                      ? () => {
                          setSelectedSlot(slot);
                          handleSlotSelected(slot);
                          setSelectedSlotCallback(slot);
                        }
                      : () => {}
                  }
                  className={`flex py-3 px-4 ${
                    selectedSlot?.timestampStart === slot.timestampStart
                      ? "slot-box-active"
                      : "slot-box"
                  } ${
                    slot?.isFree
                      ? "cursor-pointer"
                      : "slot-box-not-available cursor-not-allowed"
                  }`}
                >
                  {slot.formattedString}
                  {slot.discount && (
                    <span className="discount-strip">
                      -{slot.discountValue}
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div
            className="block space-y-8 w-10/12 md:w-4/6 lg:w-1/3 items-center"
            ref={daysContainerRef}
          >
            <div className="mt-3 flex justify-center items-center day-time-container">
              <img src={evening} width={30} alt="evening" />
              <p className="ml-1 text-xl font-semibold  text-gray-900 sm:text-xl capitalized">
                Sera
              </p>
            </div>
            {slots
              ?.filter((slot) => slot.dayTime === "evening")
              ?.map((slot) => (
                <div
                  key={slot.timestampStart}
                  onClick={
                    slot?.isFree
                      ? () => {
                          setSelectedSlot(slot);
                          handleSlotSelected(slot);
                          setSelectedSlotCallback(slot);
                        }
                      : () => {}
                  }
                  className={`flex py-3 px-4 ${
                    selectedSlot?.timestampStart === slot.timestampStart
                      ? "slot-box-active"
                      : "slot-box"
                  } ${
                    slot?.isFree
                      ? "cursor-pointer"
                      : "slot-box-not-available cursor-not-allowed"
                  }`}
                >
                  {slot.formattedString}
                  {slot.discount && (
                    <span className="discount-strip">
                      -{slot.discountValue}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

import { useEffect, useState } from "react";
import { BarberShopClientCalendarView } from "../Client/BarberShopClientCalendarView";
import { DashboardHeaderView } from "./DashboardHeaderView";
import { AdminBarberRepository } from "../../Domain/Repository";
import { useAuthStatus } from "../hook/useAuthStatus";
import { DashboardShopView } from "./DashboardShopView";
import { DashboardReservationsView } from "./DashboardReservationsView";
import { DashboardPromotionsView } from "./DashboardPromotionsView";
import { DashboardAnalyticsView } from "./DashboardAnalyticsView";
import { DashboardPersonalDataView } from "./DashboardPersonalDataView";
import { DashboardSubscriptionManagementView } from "./DashboardSubscriptionManagementView";
import { DashboardSharingView } from "./DashboardSharingView";
import { DashboardCustomersView } from "./DashboardCustomersView";

type DashboardViewProps = {
  repository: AdminBarberRepository;
};
export type DashboardTabViewProps = {
  repository: AdminBarberRepository;
  pageIndex: number;
  selectedIndex: number;
};
export function DashboardView({ repository }: DashboardViewProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const pages = [
    DashboardShopView({
      repository: repository,
      pageIndex: 0,
      selectedIndex: selectedIndex,
    }),
    DashboardReservationsView({
      repository: repository,
      pageIndex: 1,
      selectedIndex: selectedIndex,
    }),
    DashboardPromotionsView({
      repository: repository,
      pageIndex: 2,
      selectedIndex: selectedIndex,
    }),
    DashboardCustomersView({
      repository: repository,
      pageIndex: 3,
      selectedIndex: selectedIndex,
    }),
    DashboardAnalyticsView({
      repository: repository,
      pageIndex: 4,
      selectedIndex: selectedIndex,
    }),
    DashboardPersonalDataView({
      repository: repository,
      pageIndex: 5,
      selectedIndex: selectedIndex,
    }),
    DashboardSubscriptionManagementView({
      repository: repository,
      pageIndex: 6,
      selectedIndex: selectedIndex,
    }),
    DashboardSharingView({
      repository: repository,
      pageIndex: 7,
      selectedIndex: selectedIndex,
    }),
  ];

  return (
    <div>
      <DashboardHeaderView
        repository={repository}
        setPageIndexCallback={(index: number) => {
          setSelectedIndex(index);
        }}
      />
      {pages[selectedIndex]}
    </div>
  );
}

import { useState } from "react";
import { DateInfo, Slot } from "../../Domain/Model";
import { BarberRepository } from "../../Domain/Repository";
import { useBarberShopModelController } from "../hook/useBarberShopModelController";

type BarberShopClientClientViewProps = {
  repository: BarberRepository;
  userName: string;
  userPhone: string;
  userEmail: string;
  selectedSlot: Slot | null;
  isReserveButtonEnabled: boolean;
  onClearFields: (showConfirmationModal: boolean, success: boolean) => void;
  setLoading: (loading: boolean) => void;
};

export function BarberShopClientButtonsView({
  repository,
  userName,
  userPhone,
  userEmail,
  isReserveButtonEnabled,
  onClearFields,
  setLoading,
}: BarberShopClientClientViewProps) {
  const { handleClickOnBookNow } = useBarberShopModelController(repository);

  return (
    <>
      <hr className="my-4 h-0.5 border-t-0 bg-blue-100 opacity-100 dark:opacity-50" />
      <div className="pb-4 px-8 flex mx-auto justify-between items-center">
        <p
          onClick={() => onClearFields(false, false)}
          className="text-sky-800 font-semibold hover:text-sky-950 cursor-pointer"
        >
          Cancella Campi
        </p>
        <button
          disabled={!isReserveButtonEnabled}
          onClick={async () => {
            setLoading(true);
            const success: boolean = await handleClickOnBookNow(
              userName,
              userPhone,
              userEmail,
              repository
            );
            setLoading(false);
            onClearFields(true, success);
          }}
          className={` text-white font-bold py-2 px-8 md:px-16 rounded-full ${
            isReserveButtonEnabled
              ? "bg-blue-900 hover:bg-blue-800"
              : "bg-blue-900 opacity-40 cursor-not-allowed"
          }`}
        >
          Prenota
        </button>
      </div>
    </>
  );
}

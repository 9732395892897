import { BarberRepository } from "../../Domain/Repository";
import type { BarberDataSource } from "../DataSource";
import { db } from "../../firebase.config";
import { collection, doc, getDoc, onSnapshot } from "@firebase/firestore";
import {
  BarberEmployee,
  BarberShop,
  DateInfo,
  Promotion,
  Reservation,
  Service,
  Slot,
  TimeSlot,
} from "../../Domain/Model";

export class BarberRepositoryImpl implements BarberRepository {
  dataSource: BarberDataSource;
  constructor(dataSource: BarberDataSource) {
    this.dataSource = dataSource;
  }

  async setBarberShop(url: string): Promise<void> {
    await this.dataSource.setBarberShop(url);
  }

  async getBarberData(): Promise<BarberShop | null> {
    return await this.dataSource.getBarberShopInfo();
  }

  async getServices(): Promise<Service[]> {
    const result: Service[] = [];
    let barberShopInfo: BarberShop | null =
      await this.dataSource.getBarberShopInfo();
    let servicesMap: Record<string, Service> | undefined =
      barberShopInfo?.services;
    if (servicesMap !== undefined) {
      for (const [key, value] of Object.entries(servicesMap)) {
        result.push({
          name: key,
          price: value.price,
          slotDuration: value.slotDuration,
        });
      }
    }
    return result;
  }
  async getBarbers(): Promise<BarberEmployee[]> {
    const result: BarberEmployee[] = [];
    let barberShopInfo: BarberShop | null =
      await this.dataSource.getBarberShopInfo();
    let barbersMap: Record<string, Record<string, any>> | undefined =
      barberShopInfo?.employees;
    if (barbersMap !== undefined) {
      for (const [key, value] of Object.entries(barbersMap)) {
        const services = value["services"];
        const shifts = value["shifts"];
        result.push({
          name: key,
          services: services,
          shifts: shifts,
        });
      }
    }
    return result;
  }
  async getTimeSlots(
    selectedService: string,
    selectedBarber: string
  ): Promise<TimeSlot[]> {
    throw new Error("Method not implemented.");
  }

  async setSelectedService(service: Service): Promise<void> {
    await this.dataSource.setSelectedService(service);
  }
  async setSelectedBarberEmployee(barber: string): Promise<Slot[]> {
    return await this.dataSource.setSelectedBarberEmployee(barber);
  }
  async setSelectedDate(date: DateInfo): Promise<Slot[]> {
    return await this.dataSource.setSelectedDate(date);
  }

  async setSelectedSlot(slot: Slot | null): Promise<void> {
    await this.dataSource.setSelectedSlot(slot);
  }

  async bookSlot(
    userName: string,
    userPhone: string,
    userEmail: string
  ): Promise<boolean> {
    return await this.dataSource.bookSlot(userName, userPhone, userEmail);
  }
  async getReservation(
    barberId: string,
    reservationId: string
  ): Promise<Reservation | null> {
    return await this.dataSource.getReservation(barberId, reservationId);
  }

  async deleteReservationCF(
    barberId: string,
    reservationId: string
  ): Promise<boolean> {
    return await this.dataSource.deleteReservationCF(barberId, reservationId);
  }
}

import { TimeSlot } from "../../Domain/Model/TimeSlot";
import type { BarberDataSource } from "./BarberDataSource";
import { BarberEmployee } from "../../Domain/Model/BarberEmployee";
import {
  BarberShop,
  DateInfo,
  Promotion,
  Reservation,
  ReservationEmailTemplate,
  Service,
  Slot,
} from "../../Domain/Model";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import { db } from "../../firebase.config";
import { ReservationItem } from "../../Presentation";

interface CreateReservationResponse {
  success: boolean;
  reservationId: string;
}
export class OnMemoryBarberDataSourceImpl implements BarberDataSource {
  barberShop: string = "";
  barberShopInfo: BarberShop | null = null;
  services: Service[] = [];
  barbers: BarberEmployee[] = [];
  selectedService: Service | null = null;
  selectedBarberEmployee: string | null = null;
  selectedDate: DateInfo | null = null;
  selectedSlot: Slot | null = null;

  async setBarberShop(url: string): Promise<void> {
    this.barberShop = url;
    const collectionRef = collection(db, "barberShops");
    const ref = doc(collectionRef, url);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      let barberShopData: BarberShop = {
        fullName: docSnap.data().fullName,
        phone: docSnap.data().phone,
        email: docSnap.data().email,
        plan: docSnap.data().plan,
        planExpirationDate: docSnap.data().planExpirationDate,
        planActive: docSnap.data().planActive,
        stripeCustomerId: "",
        subscriptionId: "",
        invoices: [],
        businessName: docSnap.data().businessName,
        address: docSnap.data().address,
        openingTimes: docSnap.data().openingTimes,
        breakStartTime: docSnap.data().breakStartTime,
        breakEndTime: docSnap.data().breakEndTime,
        employees: docSnap.data().employees,
        services: docSnap.data().services,
        communication: docSnap.data().communication,
      };
      await this.setBarberShopInfo(barberShopData);
    } else {
      await this.setBarberShopInfo(null);
    }
  }
  async getBarberShop(): Promise<string> {
    return this.barberShop;
  }
  async setBarberShopInfo(info: BarberShop | null): Promise<void> {
    this.barberShopInfo = info;
  }

  async getBarberShopInfo(): Promise<BarberShop | null> {
    return this.barberShopInfo;
  }

  async setSelectedSlot(slot: Slot | null): Promise<void> {
    this.selectedSlot = slot;
  }
  async getSelectedSlot(): Promise<Slot | null> {
    return this.selectedSlot;
  }
  async setSelectedService(service: Service): Promise<void> {
    this.selectedService = service;
  }
  async getSelectedService(): Promise<Service | null> {
    return this.selectedService;
  }
  async setSelectedBarberEmployee(barber: string): Promise<Slot[]> {
    this.selectedBarberEmployee = barber;
    return await this.generateSlots();
  }
  async getSelectedBarberEmployee(): Promise<string | null> {
    return this.selectedBarberEmployee;
  }
  async setSelectedDate(date: DateInfo): Promise<Slot[]> {
    this.selectedDate = date;
    return await this.generateSlots();
  }
  async getSelectedDate(): Promise<DateInfo | null> {
    return this.selectedDate;
  }

  async generateSlots(): Promise<Slot[]> {
    const barberInfo: BarberShop | null = this.barberShopInfo;
    const barberEmployee: string | null = this.selectedBarberEmployee;
    const reservations: any[] = [];
    const promotions: Promotion[] = [];
    if (barberInfo === null) {
      return [];
    }
    try {
      const barberId = this.barberShop;
      const reservationsRef = collection(
        db,
        "barberShops",
        barberId,
        "reservations"
      );

      const queryReservations = query(
        reservationsRef,
        where("assignedTo", "==", barberEmployee)
      );
      const currentTimeInMs = new Date().getTime();

      const snapshot = await getDocs(queryReservations);

      snapshot.forEach((doc) => {
        const reservationData = doc.data();
        const slotEndTime = reservationData.slotEndTime;
        if (slotEndTime >= currentTimeInMs) {
          reservations.push(reservationData);
        }
      });
      const promotionsRef = collection(
        db,
        "barberShops",
        barberId,
        "promotions"
      );

      const promotionsSnapshot = await getDocs(promotionsRef);

      promotionsSnapshot.forEach((doc) => {
        const promotionData = doc.data();
        const promotion: Promotion = {
          id: doc.id,
          name: promotionData.name,
          service: promotionData.service,
          days: promotionData.days,
          startTime: promotionData.startTime,
          endTime: promotionData.endTime,
          discountValue: promotionData.discountValue,
        };
        promotions.push(promotion);
      });
    } catch (error) {
      console.error("Errore nel recupero delle prenotazioni:", error);
      return [];
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const dayName = currentDate.toLocaleString("default", { weekday: "short" });
    const monthName = currentDate.toLocaleString("default", { month: "long" });
    const selectedDate: DateInfo = this.selectedDate ?? {
      weekDayNumber: currentDate.getDay(),
      dayNumber: currentDate.getDate(),
      dayName: dayName,
      monthNumber: month,
      monthName,
      year,
      timestamp: currentDate.getTime(),
    };
    const slots: Slot[] = [];
    const barberShift: Record<number, string> | null | undefined =
      barberInfo.employees?.[barberEmployee ?? ""].shifts;

    if (
      barberShift === undefined ||
      barberShift === null ||
      barberShift[selectedDate.weekDayNumber] === undefined ||
      barberShift[selectedDate.weekDayNumber] === null
    ) {
      return [];
    }
    const openingTimes: string = barberShift[selectedDate.weekDayNumber];
    if (openingTimes === undefined || openingTimes === null) {
      return [];
    }
    const [openingTime, closingTime] = openingTimes.split("-");
    const [openingTimeHour, openingTimeMinutes] = openingTime
      .split(":")
      .map(Number);
    const [closingTimeHour, closingTimeMinutes] = closingTime
      .split(":")
      .map(Number);

    var startTime = new Date(
      selectedDate.year,
      selectedDate.monthNumber - 1,
      selectedDate.dayNumber,
      openingTimeHour,
      openingTimeMinutes
    );
    const endTime = new Date(
      selectedDate.year,
      selectedDate.monthNumber - 1,
      selectedDate.dayNumber,
      closingTimeHour,
      closingTimeMinutes
    );

    const selectedService = this.selectedService;
    const slotDuration =
      barberInfo.services[selectedService?.name!].slotDuration ?? 30;
    const slotInterval = 30;
    let currentTime = startTime;
    const [breakStartTimeHour, breakStartTimeMinutes] =
      barberInfo!.breakStartTime.split(":").map(Number);
    const [breakEndTimeHour, breakEndTimeMinutes] = barberInfo!.breakEndTime
      .split(":")
      .map(Number);
    const breakStartTime = new Date(
      selectedDate.year,
      month - 1,
      selectedDate.dayNumber,
      breakStartTimeHour,
      breakStartTimeMinutes
    );
    const breakEndTime = new Date(
      selectedDate.year,
      month - 1,
      selectedDate.dayNumber,
      breakEndTimeHour,
      breakEndTimeMinutes
    );
    while (currentTime < endTime) {
      const nextSlot = new Date(currentTime.getTime() + slotInterval * 60000);
      const slotEnd = new Date(currentTime.getTime() + slotDuration * 60000);

      const isSlotOccupied = reservations.some((slot) => {
        return (
          currentTime.getTime() >= slot.slotStartTime &&
          currentTime.getTime() < slot.slotEndTime
        );
      });

      var discountValue = "0";
      const isPromotionActive = promotions.some((promo: Promotion) => {
        const promoService = promo.service;
        if (promoService !== selectedService?.name) {
          return false;
        }
        const promoStartTime = promo.startTime;
        const promoEndTime = promo.endTime;
        const [hoursStart, minutesStart] = promoStartTime
          .split(":")
          .map(Number);
        const [hoursEnd, minutesEnd] = promoEndTime.split(":").map(Number);
        const isDayValid = promo.days.includes(currentTime.getDay());
        const currentTimeToMinutes =
          currentTime.getHours() * 60 + currentTime.getMinutes();
        const promoStartMinutes = hoursStart * 60 + minutesStart;
        const promoEndMinutes = hoursEnd * 60 + minutesEnd;
        const isValidSlot =
          currentTimeToMinutes >= promoStartMinutes &&
          currentTimeToMinutes < promoEndMinutes;
        if (isDayValid && isValidSlot) {
          discountValue = promo.discountValue;
        }
        return isDayValid && isValidSlot;
      });

      const isFutureSlot = currentTime.getTime() > new Date().getTime();
      const isDuringBreakTime =
        (currentTime.getTime() >= breakStartTime.getTime() &&
          currentTime.getTime() < breakEndTime.getTime()) ||
        (slotEnd.getTime() > breakStartTime.getTime() &&
          slotEnd.getTime() <= breakEndTime.getTime());
      if (isFutureSlot && !isDuringBreakTime && slotEnd <= endTime) {
        const slotString = `${this.formatTime(currentTime)} - ${this.formatTime(
          slotEnd
        )}`;
        var dayTime: string = "morning";
        if (currentTime.getHours() >= 11 && currentTime.getHours() < 14) {
          dayTime = "lunch";
        } else if (
          currentTime.getHours() >= 14 &&
          currentTime.getHours() < 17
        ) {
          dayTime = "afternoon";
        } else if (currentTime.getHours() >= 17) {
          dayTime = "evening";
        }
        slots.push({
          timestampStart: currentTime.getTime(),
          timestampEnd: currentTime.getTime() + slotDuration * 60000,
          formattedString: slotString,
          dayTime: dayTime,
          isFree: !isSlotOccupied,
          discount: isPromotionActive,
          discountValue: discountValue,
        });
      }

      currentTime = nextSlot;
    }

    return slots;
  }

  async bookSlot(
    userName: string,
    userPhone: string,
    userEmail: string
  ): Promise<boolean> {
    try {
      const barberInfo: BarberShop | null = this.barberShopInfo;
      const selectedDate: DateInfo | null = this.selectedDate;
      const selectedService: Service | null = this.selectedService;
      const selectedBarberEmployee: string | null = this.selectedBarberEmployee;
      const selectedSlot: Slot | null = this.selectedSlot;
      const functions = getFunctions();
      const callableCreateReservation = httpsCallable(
        functions,
        "createReservation"
      );
      if (
        selectedService === null ||
        selectedBarberEmployee === null ||
        selectedSlot === null
      ) {
        return false;
      }

      const barberId = this.barberShop;
      const reservationsRef = collection(
        db,
        "barberShops",
        barberId,
        "reservations"
      );
      const queryReservations = query(
        reservationsRef,
        where("assignedTo", "==", selectedBarberEmployee),
        where("slotStartTime", "==", selectedSlot.timestampStart)
      );

      const existingAppointments = await getDocs(queryReservations);
      if (!existingAppointments.empty) {
        return false;
      }
      const discountValue: string =
        this.selectedSlot?.discountValue === "0"
          ? "Non applicabile"
          : `-${this.selectedSlot!.discountValue}%`;

      const createReservationResponse: any = await callableCreateReservation({
        userName: userName,
        userPhone: userPhone,
        userEmail: userEmail,
        selectedService: selectedService,
        selectedBarberEmployee: selectedBarberEmployee,
        selectedSlot: selectedSlot,
        barberShopId: barberId,
      });

      const reservationId: string | null | undefined =
        createReservationResponse.data.reservationId;
      if (
        barberInfo !== null &&
        selectedService !== null &&
        selectedDate !== null &&
        reservationId !== null &&
        reservationId !== undefined
      ) {
        this.sendConfirmationEmail({
          service_id: process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
          template_id: process.env.REACT_APP_EMAIL_JS_RESERVATION_TEMPLATE_ID,
          user_id: process.env.REACT_APP_EMAIL_JS_USER_ID,
          template_params: {
            barber_shop_name: barberInfo!.businessName,
            user_name: userName,
            barber_shop_address: barberInfo!.address,
            reservation_date:
              selectedDate!.dayNumber +
              " " +
              selectedDate!.monthName +
              " " +
              selectedDate.year,
            slot_time: selectedSlot.formattedString,
            selected_service: selectedService?.name ?? "",
            selected_barber_employee: selectedBarberEmployee,
            price: selectedService!.price + "€",
            discount: discountValue,
            to_email: userEmail,
            delete_url: `https://gobarber.it/app/barber/${barberId}/delete/${reservationId}`,
          },
        });
      }
      return true;
    } catch (error) {
      console.error("Errore durante la prenotazione dello slot:", error);
      return false;
    }
  }

  async sendConfirmationEmail(data: ReservationEmailTemplate): Promise<void> {
    await axios.post(`https://api.emailjs.com/api/v1.0/email/send`, data);
  }

  async getReservation(
    barberId: string,
    reservationId: string
  ): Promise<Reservation | null> {
    try {
      const collectionRef = collection(
        db,
        "barberShops",
        barberId,
        "reservations"
      );
      const ref = doc(collectionRef, reservationId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const reservation = {
          ...docSnap.data(),
          id: docSnap.id,
        } as Reservation;
        return reservation;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async deleteReservationCF(
    barberId: string,
    reservationId: string
  ): Promise<boolean> {
    try {
      const functions = getFunctions();
      const callableDeleteReservation = httpsCallable(
        functions,
        "deleteReservation"
      );
      await callableDeleteReservation({
        barberId: barberId,
        reservationId: reservationId,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  formatTime(time: Date): string {
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }
}

import { AdminBarberRepository } from "../../Repository";

export async function clickOnCreateAccount(
  userName: string,
  userEmail: string,
  userPhone: string,
  userPassword: string,
  repository: AdminBarberRepository
): Promise<boolean> {
  return repository.createAccount(userName, userEmail, userPhone, userPassword);
}

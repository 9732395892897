import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { useState, Fragment, useEffect } from "react";
import { BarberEmployee, BarberShop, Service } from "../../Domain/Model";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { AdminBarberRepository } from "../../Domain/Repository";
import {
  daysOfWeekNumber,
  daysOfWeekString,
  slots,
} from "../../Utils/DateUtil";

type AddBarberEmployeeComponentProps = {
  previousBarberName: string;
  allServices: string[];
  previousServices: string[];
  previousShifts: Record<number, string>;
  setAddBarberModeCallback: (employee: BarberEmployee | null) => void;
  repository: AdminBarberRepository;
};

export function AddBarberEmployeeComponent({
  previousBarberName,
  allServices,
  previousServices,
  previousShifts,
  setAddBarberModeCallback,
  repository,
}: AddBarberEmployeeComponentProps) {
  const [employeeName, setEmployeeName] = useState<string>(previousBarberName);
  const [services, setServices] = useState<string[]>(previousServices);
  const [shiftsStart, setShiftsStart] = useState<Record<number, string>>({});
  const [shiftsEnd, setShiftsEnd] = useState<Record<number, string>>({});

  const { handleAddBarberEmployee } =
    useAdminBarberShopModelController(repository);

  const handleAddRemoveService = (serviceName: string) => {
    var newServices = [];
    if (services.includes(serviceName)) {
      newServices = services.filter((element) => element !== serviceName);
    } else {
      newServices = [...services];
      newServices.push(serviceName);
    }
    setServices(newServices);
  };

  function handleShiftChange(
    index: number,
    value: string,
    isStartTime: boolean
  ) {
    if (isStartTime) {
      setShiftsStart((prevStart) => ({ ...prevStart, [index]: value }));
    } else {
      setShiftsEnd((prevEnd) => ({ ...prevEnd, [index]: value }));
    }
  }
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const addBarberEmployee = async () => {
    const shifts: Record<number, string> = {};
    for (let i = 0; i < 7; i++) {
      if (
        shiftsStart[i] !== undefined &&
        shiftsStart[i] !== null &&
        shiftsStart[i] !== "" &&
        shiftsStart[i] !== slots[0]
      ) {
        const endTime = shiftsEnd[i] ?? slots[slots.length - 1];
        const fullShiftString = shiftsStart[i] + "-" + endTime;
        shifts[i] = fullShiftString;
      }
    }
    const employee: BarberEmployee = {
      name: employeeName,
      services: services,
      shifts: shifts,
    };
    const result = await handleAddBarberEmployee(employee);
    if (result) {
      setAddBarberModeCallback(employee);
    } else {
      setAddBarberModeCallback(null);
    }
  };

  useEffect(() => {
    if (Object.keys(previousShifts).length > 0) {
      var previousShiftsStart: Record<number, string> = {};
      var previousShiftsEnd: Record<number, string> = {};
      for (const [key, value] of Object.entries(previousShifts)) {
        const startTime = value.split("-")[0];
        const endTime = value.split("-")[1];
        previousShiftsStart[parseInt(key)] = startTime;
        previousShiftsEnd[parseInt(key)] = endTime;
      }
      setShiftsStart(previousShiftsStart);
      setShiftsEnd(previousShiftsEnd);
    }
  }, []);
  return (
    <div>
      <div>
        <label
          htmlFor="employeeName"
          className="mt-5 block text-sm font-medium leading-6 text-black"
        >
          Nome e Cognome
        </label>
        <div className="mt-2">
          <input
            id="employeeName"
            name="employeeName"
            type="text"
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            required
            className="block  w-full lg:w-2/3 rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
          />
        </div>
      </div>
      {daysOfWeekString.map((day, index) => (
        <div key={day} className="block mt-5">
          <div className="mb-3">{day}</div>
          <div className="block mb-5">
            <div className="mb-3 w-full lg:w-1/3">
              <Listbox
                value={shiftsStart[daysOfWeekNumber[index]] ?? ""}
                onChange={(value) =>
                  handleShiftChange(daysOfWeekNumber[index], value, true)
                }
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Orario inizio turno
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {shiftsStart[daysOfWeekNumber[index]]
                              ? shiftsStart[daysOfWeekNumber[index]]
                              : "Inizio turno"}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                          {slots.map((time, idx) => (
                            <Listbox.Option
                              key={time}
                              value={time}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center justify-between">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {time}
                                    </span>
                                  </div>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div className="mb-3 w-full lg:w-1/3">
              <Listbox
                value={shiftsEnd[daysOfWeekNumber[index]] ?? ""}
                disabled={
                  shiftsStart[daysOfWeekNumber[index]] === "" ||
                  shiftsStart[daysOfWeekNumber[index]] === null ||
                  shiftsStart[daysOfWeekNumber[index]] === undefined ||
                  shiftsStart[daysOfWeekNumber[index]] === slots[0]
                }
                onChange={(value) =>
                  handleShiftChange(daysOfWeekNumber[index], value, false)
                }
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">
                      Orario fine turno
                    </Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {shiftsEnd[daysOfWeekNumber[index]]
                              ? shiftsEnd[daysOfWeekNumber[index]]
                              : "Fine turno"}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                          {slots
                            .slice(
                              slots.indexOf(
                                shiftsStart[daysOfWeekNumber[index]]
                              ) + 1
                            )
                            .map((time) => (
                              <Listbox.Option
                                key={time}
                                value={time}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-indigo-600 text-white"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center justify-between">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {time}
                                      </span>
                                    </div>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
        </div>
      ))}

      <div className="mt-10 space-y-10">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900">
            Servizi
          </legend>
          {allServices.map((value) => (
            <div className="mt-6 space-y-6">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="value"
                    name="value"
                    type="checkbox"
                    checked={services.includes(value)}
                    onChange={() => {
                      handleAddRemoveService(value);
                    }}
                    className="h-4 w-4 rounded border-gray-300 accent-slate-700"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="value" className="font-medium text-gray-900">
                    {value}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </fieldset>
      </div>

      <button
        disabled={employeeName === ""}
        className={`mt-10 flex items-center justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
          employeeName !== "" && services.length > 0
            ? "cursor-default"
            : "cursor-not-allowed opacity-40"
        }`}
        onClick={() => {
          addBarberEmployee();
        }}
      >
        Salva
      </button>
    </div>
  );
}

import { BarberRepository } from "../../Repository";

export async function clickOnBookNow(
  userName: string,
  userPhone: string,
  userEmail: string,
  repository: BarberRepository
): Promise<boolean> {
  return repository.bookSlot(userName, userPhone, userEmail);
}

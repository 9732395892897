import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logoWhite from "../../images/logo_white.svg";
import { AdminBarberRepository } from "../../Domain/Repository/AdminBarberRepository";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { useLocation } from "react-router-dom";
import { useAuthStatus } from "../hook/useAuthStatus";
import { UserCircleIcon, UserIcon } from "@heroicons/react/20/solid";
import guide_pdf from "../../../src/gobarber_guide.pdf";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type DashboardHeaderViewProps = {
  repository: AdminBarberRepository;
  setPageIndexCallback: (index: number) => void;
};

export function DashboardHeaderView({
  repository,
  setPageIndexCallback,
}: DashboardHeaderViewProps) {
  const { handleSignOut } = useAdminBarberShopModelController(repository);

  const navigation = [
    "Negozio",
    "Prenotazioni",
    "Promozioni",
    "Clienti",
    "Analisi",
  ];
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);

  const changePageIndex = (index: number) => {
    setSelectedPageIndex(index);
    setPageIndexCallback(index);
  };
  return (
    <Disclosure as="nav" className="bg-blue-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Apri menù</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    src={logoWhite}
                    alt="gobarber logo"
                    className="h-8 sm:h-11"
                    onClick={() => changePageIndex(0)}
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex items-center justify-center">
                  <div className="flex space-x-4">
                    {navigation.map((item, index) => (
                      <p
                        key={item}
                        onClick={() => changePageIndex(index)}
                        className={classNames(
                          index == selectedPageIndex
                            ? "bg-white text-black"
                            : "text-gray-300 hover:bg-blue-500 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                        )}
                        aria-current={
                          index == selectedPageIndex ? "page" : undefined
                        }
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full text-sm focus:outline-none">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Apri menù profilo</span>
                      <UserCircleIcon className="text-white h-10" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() => changePageIndex(navigation.length)}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Dati personali
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() =>
                              changePageIndex(navigation.length + 1)
                            }
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Gestione abbonamento
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() =>
                              changePageIndex(navigation.length + 2)
                            }
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Condivisione
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() => window.open(guide_pdf, "_blank")}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Guida
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() =>
                              window.open(
                                "https://api.whatsapp.com/send?phone=393286623047",
                                "_blank"
                              )
                            }
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Assistenza
                          </p>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={() => handleSignOut()}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Esci
                          </p>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item, index) => (
                <Disclosure.Button
                  key={item}
                  as="a"
                  onClick={() => changePageIndex(index)}
                  className={classNames(
                    index == selectedPageIndex
                      ? "bg-white text-black"
                      : "text-gray-300 hover:bg-blue-500 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={index == selectedPageIndex ? "page" : undefined}
                >
                  {item}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

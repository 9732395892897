import { UserCircleIcon } from "@heroicons/react/20/solid";
import { Reservation } from "../../Domain/Model";
import { AdminBarberRepository } from "../../Domain/Repository";
import { useState } from "react";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";

type ReservationItemProps = {
  reservation: Reservation;
  onDelete: () => void;
  repository: AdminBarberRepository;
};

export function ReservationItem({
  reservation,
  onDelete,
  repository,
}: ReservationItemProps) {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  return (
    <>
      <li key={reservation.id} className="flex justify-between gap-x-6 py-5">
        <div className="flex min-w-1 gap-x-4">
          <UserCircleIcon className="h-12 w-12 text-sky-950" />
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              {reservation.customerName}
            </p>
            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
              {reservation.customerPhone}
            </p>
          </div>
        </div>
        <div className="shrink-1 text-end sm:flex sm:flex-col sm:items-end">
          <p className="text-sm leading-6 text-gray-900">
            {`${reservation.service.name} con ${reservation.assignedTo}`}
          </p>
          <p className="mt-1 text-xs leading-5 text-gray-500">
            {reservation.formattedString}
          </p>
          <button
            type="button"
            className="mt-4 font-medium text-sm text-neutral-950 hover:text-neutral-800"
            onClick={() => setShowDeleteConfirmationDialog(true)}
          >
            Elimina
          </button>
        </div>
      </li>
      {showDeleteConfirmationDialog && (
        <DeleteConfirmationDialog
          title={"Conferma eliminazione"}
          text={`Stai per eliminare l'appuntamento di ${reservation.customerName}`}
          onDismiss={() => setShowDeleteConfirmationDialog(false)}
          onDeleteConfirmed={() => {
            setShowDeleteConfirmationDialog(false);
            onDelete();
          }}
        />
      )}
    </>
  );
}

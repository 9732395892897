import no_slot from "../../images/no_slots.png";

export function NoSlotsAvailable() {
  return (
    <div className="flex mx-auto w-full justify-center items-center py-10 flex-col">
      <img src={no_slot} alt="leaf" className="h-24 w-24" />
      <p className="mt-3 text-lg text-center px-3">
        Nessuna disponibilità in questo giorno
      </p>
    </div>
  );
}

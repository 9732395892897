import logo from "../logo.svg";
import "./App.css";
import "react-calendar/dist/Calendar.css";
import "../firebase.config";
import { analytics } from "../firebase.config";
import { useEffect } from "react";
import { OnMemoryBarberDataSourceImpl, BarberRepositoryImpl } from "../Data";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SignUpView } from "../Presentation/Admin/SignUpView";
import { OnMemoryAdminBarberDataSourceImpl } from "../Data/DataSource";
import { AdminBarberRepositoryImpl } from "../Data/Repository/AdminBarberRepositoryImpl";
import { NotFoundView } from "../Presentation/Client/NotFoundView";
import { LandingPageView } from "../Presentation/Landing/LandingPageView";
import { PrivacyPolicyView } from "../Presentation/Landing/PrivacyPolicyView";
import { TermsAndConditionsView } from "../Presentation/Landing/TermsAndConditionsView";
import { BarberShopClientView } from "../Presentation/Client/BarberShopClientView";
import { SignInView } from "../Presentation/Admin/SignInView";
import { PrivateRoute } from "../Presentation/Admin/PrivateRoute";
import { DashboardView } from "../Presentation/Admin/DashboardView";
import { PaymentConfirmedView } from "../Presentation/Admin/PaymentConfirmedView";
import { CreditsView, DeleteClientReservationView } from "../Presentation";
import { setAnalyticsCollectionEnabled } from "firebase/analytics";

const dataSource = new OnMemoryBarberDataSourceImpl();
const repository = new BarberRepositoryImpl(dataSource);
const adminDataSource = new OnMemoryAdminBarberDataSourceImpl();
const adminRepository = new AdminBarberRepositoryImpl(adminDataSource);

function App() {
  useEffect(() => {
    if (getCookie("consent") == "yes" && getCookie("analytics") == "yes") {
      setAnalyticsCollectionEnabled(analytics, true);
    } else {
      setAnalyticsCollectionEnabled(analytics, false);
    }
  }, []);
  function getCookie(key: string): string | undefined {
    const cookies: Record<string, string> = document.cookie
      .split(";")
      .reduce((ac: Record<string, string>, cv: string) => {
        const [cookieKey, cookieValue] = cv.split("=").map((str) => str.trim());
        return { ...ac, [cookieKey]: cookieValue };
      }, {});

    const cookieYesConsent = cookies["cookieyes-consent"];
    if (!cookieYesConsent) return undefined;

    const cookiePairs: Record<string, string> = cookieYesConsent
      .split(",")
      .reduce((obj: Record<string, string>, pair: string) => {
        const [pairKey, pairValue] = pair.split(":");
        obj[pairKey] = pairValue;
        return obj;
      }, {});

    return cookiePairs[key];
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFoundView />} />
        <Route path="/" element={<LandingPageView />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsView />}
        />
        <Route path="/credits" element={<CreditsView />} />

        <Route
          path="/app/barber/:id"
          element={<BarberShopClientView repository={repository} />}
        />
        <Route
          path="/app/barber/:id/delete/:reservation"
          element={<DeleteClientReservationView repository={repository} />}
        />
        <Route
          path="/signup"
          element={<SignUpView repository={adminRepository} />}
        />
        <Route
          path="/signin"
          element={<SignInView repository={adminRepository} />}
        />
        <Route path="/app/dashboard" element={<PrivateRoute />}>
          <Route
            index
            element={<DashboardView repository={adminRepository} />}
          />
        </Route>
        <Route
          path="/app/dashboard/subscription-success"
          element={<PrivateRoute />}
        >
          <Route
            index
            element={<PaymentConfirmedView repository={adminRepository} />}
          />
        </Route>
      </Routes>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;

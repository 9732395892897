import { Listbox, Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { useState, Fragment } from "react";
import { BarberShop, Service } from "../../Domain/Model";
import { useAdminBarberShopModelController } from "../hook/useAdminBarberShopModelController";
import { AdminBarberRepository } from "../../Domain/Repository";

type AddServiceComponentProps = {
  previousServiceName: string;
  previousServiceDuration: number;
  previousServicePrice: string;
  setAddServiceModeCallback: (service: Service | null) => void;
  repository: AdminBarberRepository;
};

export function AddServiceComponent({
  previousServiceName,
  previousServiceDuration,
  previousServicePrice,
  setAddServiceModeCallback,
  repository,
}: AddServiceComponentProps) {
  const [serviceName, setServiceName] = useState<string>(previousServiceName);
  const [serviceDuration, setServiceDuration] = useState<number>(
    previousServiceDuration
  );
  const [servicePrice, setServicePrice] =
    useState<string>(previousServicePrice);
  const { handleAddService } = useAdminBarberShopModelController(repository);
  const serviceDurationOptions: Record<number, string> = {
    30: "30 minuti",
    45: "45 minuti",
    60: "1 ora",
    90: "1 ora e 30 minuti",
    120: "2 ore",
    150: "2 ore e 30 minuti",
    180: "3 ore",
    210: "3 ore e 30 minuti",
    240: "4 ore",
  };
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <div>
        <label
          htmlFor="serviceName"
          className="mt-5 block text-sm font-medium leading-6 text-black"
        >
          Nome del servizio
        </label>
        <div className="mt-2">
          <input
            id="serviceName"
            name="serviceName"
            type="text"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            required
            className="block w-full rounded-md py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
          />
        </div>
      </div>
      <Listbox
        value={serviceDuration}
        onChange={(value) => {
          setServiceDuration(value);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-black">
              Durata del servizio
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate">
                    {serviceDurationOptions[serviceDuration]}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {open ? (
                    <ChevronUpIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                  {Object.entries(serviceDurationOptions).map(
                    ([key, value]) => (
                      <Listbox.Option
                        key={key}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={key}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center justify-between">
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {value}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div>
        <label
          htmlFor="servicePrice"
          className="mt-5 block text-sm font-medium leading-6 text-black"
        >
          Prezzo
        </label>
        <div className="relative mt-2 mx-auto rounded-md">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">€</span>
          </div>
          <input
            id="servicePrice"
            name="servicePrice"
            type="number"
            value={servicePrice}
            onChange={(e) => setServicePrice(e.target.value)}
            placeholder="0"
            required
            className="block w-1/3 sm:w-1/5 appearance-none rounded-md border-0 py-1.5 !pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
          />
        </div>
      </div>
      <button
        disabled={serviceName === ""}
        className={`mt-10 flex items-center justify-center rounded-md bg-slate-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
          serviceName !== ""
            ? "cursor-default"
            : "cursor-not-allowed opacity-40"
        }`}
        onClick={async () => {
          const result = await handleAddService({
            name: serviceName,
            price: servicePrice,
            slotDuration: serviceDuration,
          });

          setAddServiceModeCallback(
            result
              ? {
                  name: serviceName,
                  price: servicePrice,
                  slotDuration: serviceDuration,
                }
              : null
          );

          setServiceName("");
          setServiceDuration(30);
          setServicePrice("");
        }}
      >
        Salva
      </button>
    </div>
  );
}
